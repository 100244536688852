@import '../../assets/styles/abstracts/variables';

body {
  height: 100%;
}

.addPoll {
  display: flex;
  flex: 1;
  padding: 1.8rem 2rem 2rem;

  @media (max-width: 375px) {
    padding: 1.8rem 1rem 2rem;
  }

  &__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(1rem);
  }

  &__wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
  }

  &--withBgImg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__header {
    margin-bottom: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &__title {
      @include montserrat-22-semi-bold;
      color: #ffffff;
      text-align: center;
      flex: 1;
    }

    &__close {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__tab {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border-radius: 20px;
    padding: 1rem 1.5rem;
    opacity: 0.6;

    @media (max-width: 375px) {
      padding: 0.5rem 1rem;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      &__text {
        @include montserrat-12-semi-bold;
      }
    }

    &--active {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &__text {
      @include montserrat-14-semi-bold;
      color: #0d1444;
      margin-left: 1rem;
      text-transform: uppercase;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    padding-top: 1rem;
  }
  &__footer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .button {
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}

.yesNoQuestion {
  width: 100%;

  &__input {
    position: relative;
    margin-bottom: 2.4rem;
    input {
      @include montserrat-22-semi-bold;
      color: #ffffff;
      text-align: center;
      width: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ffffff;
        opacity: 0.7;
      }
    }
    &__error {
      @include montserrat-12-normal;
      color: red;
      position: absolute;
      bottom: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button {
    @include montserrat-18-semi-bold;
    width: 11.9rem;
    height: 6rem;
    text-transform: uppercase;
    background: linear-gradient(92.95deg, #ffffff -12.15%, #ffffff 111.4%);
    display: flex;
    justify-content: center;
    align-items: center;

    &--yes {
      border-top-left-radius: 3rem;
      border-bottom-left-radius: 3rem;
      margin-right: 1px;
      color: #1bbc5c;
    }
    &--no {
      border-top-right-radius: 3rem;
      border-bottom-right-radius: 3rem;
      color: rgba(215, 35, 35, 0.81);
    }
  }
}

.poll {
  width: 80%;
  border-radius: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;
    padding: 2.2rem 0;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
      opacity: 0.7;
    }

    input {
      @include montserrat-18-semi-bold;
      color: #ffffff;
      text-align: center;
      width: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      position: relative;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }

  &__content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      opacity: 0.8;
    }
    &__error {
      @include montserrat-12-normal;
      color: red;
      position: absolute;
      bottom: 0.5rem;
    }
  }
  &__option {
    background: #ffffff;
    padding: 1.2rem;
    border-radius: 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    flex: 1;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &--empty {
      .poll__option__icon {
        border: 1px solid #bcbcbc;
        color: #bcbcbc;
      }
    }
    &--add {
      border: 1px dashed #d6d6d6;
      .poll__option__icon {
        border: 1px solid #bcbcbc;
        color: #bcbcbc;
        font-size: 2rem;
      }
    }

    &__icon {
      @include montserrat-11-normal;
      min-width: 2.6rem;
      min-height: 2.6rem;
      border: 1px solid #0d1444;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.2rem;
    }
    &__input {
      input {
        @include montserrat-14-semi-bold;
        color: #0d1444;
        width: 100%;
        background-color: transparent;
        border: none;
        box-shadow: none;
        position: relative;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #bcbcbc;
        }
      }
    }
    &__text {
      @include montserrat-14-semi-bold;
      color: #bcbcbc;
    }
  }
}
.askQuestion {
  width: 80%;
  border-radius: 2rem;
  overflow: hidden;

  &__header {
    position: relative;
    padding: 2.2rem 0;
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
      opacity: 0.7;
    }
    &__error {
      @include montserrat-12-normal;
      color: red;
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }

    input {
      @include montserrat-18-semi-bold;
      color: #ffffff;
      text-align: center;
      width: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      position: relative;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }

  &__content {
    position: relative;
    padding: 2rem;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: #ffffff;
      opacity: 0.8;
    }
  }
  &__option {
    background: #ffffff;
    padding: 1.2rem;
    border-radius: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 5rem;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &--empty {
      .poll__option__icon {
        border: 1px solid #bcbcbc;
        color: #bcbcbc;
      }
    }
    &--add {
      border: 1px dashed #d6d6d6;
      .poll__option__icon {
        border: 1px solid #bcbcbc;
        color: #bcbcbc;
        font-size: 2rem;
      }
    }

    &__icon {
      @include montserrat-11-normal;
      width: 2.6rem;
      height: 2.6rem;
      border: 1px solid #0d1444;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1.2rem;
    }
    &__input {
      input {
        @include montserrat-14-semi-bold;
        color: #0d1444;
        width: 100%;
        background-color: transparent;
        border: none;
        box-shadow: none;
        position: relative;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #bcbcbc;
        }
      }
    }
    &__text {
      @include montserrat-14-semi-bold;
      color: #bcbcbc;
    }
  }
}
