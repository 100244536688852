@import '../../assets/styles/abstracts/variables';

.uploadCard {
  width: 100%;
  background: $white89deg;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
  min-height: 5rem;
  transition: all ease-in-out 0.3s;
  border: 2px solid transparent;
  position: relative;
}

.icon {
  @include flex-center;
  position: relative;
  min-width: 5rem;
  height: 5rem;
  background: #f7f7f7;
  border-radius: 20px;
  margin-right: 1.5rem;
  overflow: hidden;
}

.progressBar {
  @include montserrat-12-normal;
  width: 100%;
  padding: 0;
  font-weight: 500;
  margin: 0;
  color: #778797;

  progress {
    width: 100%;
  }
}

.fileInput {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.body {
  width: 100%;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.title {
  @include montserrat-14-semi-bold;
  margin: 0;
  color: #0d1444;
  word-break: break-all;
}

.body {
  display: flex;
}

.error {
  @include montserrat-12-normal;
  padding: 0;
  font-weight: 500;
  margin: 0;
  color: red;
}
