@import './assets/styles/abstracts/variables';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  background: $white180deg;
  overscroll-behavior: none;
}
body.dark-theme {
  background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
}

#root {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  // height: 100%;
}

#portal {
  min-height: 0;
  display: flex;

  &.portal--open {
    height: 100%;
    flex: 1;
  }
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

@font-face {
  font-family: 'SF-Pro-Display';
  src:
    local('SF-Pro-Display'),
    url('./fonts/SF-Pro-Display/FontsFree-Net-SFProDisplay-Regular.ttf') format('truetype');
  font-weight: bold;
}

/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
