.container {
  padding: 20px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: #f2f6f9;
  background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
  height: 210px;
  z-index: 4;
  font-family: 'Montserrat', sans-serif;
  * {
    font-family: inherit;
  }
}

.swiper_container {
  width: 100%;
  display: flex;
  margin-top: -20px;
}

.loader_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input {
  font-size: 14px;
  padding-left: 11px;
  padding-top: 16px;
  padding-bottom: 17px;
  padding-right: 34px;
  background: #fffcfc;
  border: 1px solid rgba(38, 42, 51, 0.2);
  border-radius: 15px;
  outline: none;
  width: 100%;
  margin-top: 0;
}

.input_container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.input_wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}

.submit_button {
  cursor: pointer;
  user-select: none;
  width: 50px;
  height: 50px;
  box-shadow: 0px 4px 14px rgba(47, 152, 254, 0.5);
  border: 3px solid #76bcff;
  background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  flex-shrink: 0;
  margin-left: 10px;
}

.powered_by_logo {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  img {
    width: 75px;
    height: auto;
    margin-right: 66px;
    margin-top: 5px;
    @media (max-width: 330px) {
      width: 50px;
    }
  }
}

.input_button_wrapper {
  position: relative;
  flex: 1;
}

.giphy_close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.no_results {
  height: 119px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #778797;
}
