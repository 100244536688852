@import '../abstracts/variables';

@mixin ll__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 3rem;
}

@mixin ll__wrapper__title {
  font-family: $montserrat;
  font-weight: 600;
  font-size: 2.6rem;
  text-align: center;
  line-height: 3.2rem;
  margin-top: 3.8rem;
}

@mixin ll__wrapper__form {
  width: 100%;
  padding: 0 4rem;

  .inputfield {
    margin-bottom: 2rem;

    input {
      @include montserrat-14-normal;
    }
  }

  .checkboxField label {
    @include montserrat-14-normal;
  }
}
