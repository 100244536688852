@import '../../assets/styles/abstracts/variables';

.fans {
  &__search {
    display: flex;
    align-items: center;

    &__searchfield {
      flex: 1;
    }

    &__filter {
      &:last-of-type {
        margin: 0;
      }
    }

    .dropdown__body {
      top: 6rem;
    }
  }

  &__addlist {
    display: flex;
    align-items: center;
    height: 7.6rem;
    background: $white89deg;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 1rem;

    &__icon {
      @include flex-center;
      width: 5.8rem;
      height: 5.8rem;
      height: 100%;
      background: #f7f7f7;
      border-radius: 20px;
      margin-right: 5rem;

      img {
        width: 3rem;
        filter: brightness(0.75);
      }
    }

    &__text {
      @include montserrat-14-bold;
      color: #afafaf;
    }
  }

  &__active {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem 2rem 1rem;

    .tabs {
      margin-bottom: 0;
    }

    &__number {
      @include montserrat-10-normal;
      font-weight: 500;
      color: #828c94;
      padding: 0.2rem 0.8rem;
      background: #fff;
      border-radius: 5px;

      b {
        color: #000;
      }
    }
  }
}

// FANS CUSTOM GROUP

.fansCustomGroup--h1 {
  @include montserrat-18-semi-bold;
  color: #0d1444;
  overflow: hidden;
  word-wrap: break-word;
}

.actionCard__fans__icons {
  display: flex;
  align-items: center;

  div,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem;
    border: 1px solid #f4f4f4;
    background-color: #fff;
    height: 4rem;
    width: 4rem;
  }

  &--twoPeople {
    margin-right: 1rem;
  }
}

.fans-basic-layout {
  .whs__wrapper {
    display: flex;
    flex-direction: column;
  }
}

.fans-home-loader-container {
  height: 100%;
  width: 100%;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fans-group-action-card {
  .actionCard__body {
    overflow: hidden;
  }
  .actionCard__body__text {
    word-wrap: break-word;
  }
  .actionCard__body__description {
    display: flex;
  }
  .actionCard--avatar {
    border-radius: 50%;
  }
}

.fans-group-action-card-custom {
  .actionCard__body {
    padding-right: 35px;
  }
}

.fans-custom-group-title-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fans-group-add-button {
  box-shadow: 0px 4px 14px rgba(162, 162, 162, 0.2);
}

.fans-custom-group-count {
  font-family: 'Montserrat', sans-serif;
  padding: 5px 10px;
  background-color: #ffffff;
  color: #6d6d6d;
  font-size: 11px;
  border-radius: 5px;
  min-width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #000000;
    font-weight: 600;
    margin-right: 3px;
  }
}

.fans-custom-group-active-filter {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #262a33;
  border-radius: 10px;
  padding: 6px 12px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 12px;
  margin-right: auto;
  span {
    font-weight: 600;
    margin-left: 5px;
  }
  margin-bottom: 20px;
}

.fans-custom-group-active-filter-close {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  user-select: none;
}

.fans-group-sort-option-tag {
  background-color: rgba(#e7e7e7, 0.5);
  padding: 4px 10px;
  border-radius: 5px;
  font-weight: 500;
  color: #828c94;
  span {
    font-weight: 600;
    color: #262a33;
  }
}

.fans-groups-drawer-button {
  background: rgba($color: #f1f1f1, $alpha: 0.5);
  border-radius: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  svg {
    circle {
      transition: fill ease-in-out 0.3s;
    }
  }
  &:hover {
    svg {
      circle {
        fill: #2894ff;
      }
    }
  }
  &-open {
    svg {
      circle {
        fill: #2894ff;
      }
    }
  }
}

.fans-groups-drawer-scrollblock {
  overflow: hidden !important;
}

.fans-groups-drawer-overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
  &-background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
  &-open {
    opacity: 1;
    visibility: visible;
    .fans-groups-drawer {
      transform: translateY(0);
      box-shadow: 0px -20px 40px rgba(17, 29, 41, 0.6);
    }
  }
}

.fans-groups-drawer {
  background: #ffffff;
  border-radius: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  height: 200px;
  z-index: 3;
  transform: translateY(100%);
  transition: all ease-in-out 0.3s;
  &-option {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0;
    transition: color ease-in-out 0.3s;
    color: #0d1444;
    &:first-of-type {
      border-bottom: 1px solid #d9d9d9;
    }
    svg {
      margin-right: 15px;
    }
    &:hover {
      color: #2894ff;
    }
  }
}

.fans-groups-drawer-button-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
