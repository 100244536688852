.add-users-to-groups-modal-container {
  display: flex;
  flex-direction: column;
}

.add-user-to-groups-separator {
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
  margin: 20px 0;
}

.add-user-to-groups-container {
  display: flex;
  flex-direction: column;
  height: 330px;
  overflow-y: auto;
  padding-bottom: 76px;
}

.add-user-to-groups-loader {
  margin: auto;
  height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-user-to-groups-action-card {
  flex-shrink: 0;
  flex-grow: 0;
  .actionCard__body {
    overflow: hidden;
  }
  .actionCard__body__text {
    word-wrap: break-word;
  }
  .actionCard__body__description {
    display: flex;
  }
  .actionCard--avatar {
    border-radius: 50%;
  }
}

.add-user-to-groups-add-button {
  box-shadow: 0px 4px 14px rgba(162, 162, 162, 0.2);
}

.add-user-to-groups-select-all-container {
  display: flex;
  align-items: center;
}

.add-user-to-groups-select-all-text {
  margin-left: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #828c94;
}

.add-user-to-groups-button-container-wrapper {
  height: 96px;
  position: sticky;
  bottom: 0;
  left: 0;
  width: calc(100% + 6rem);
  transform: translate(-3rem, 2rem);
  background-color: #ffffff;
}

.add-user-to-groups-button-container {
  height: 96px;
  width: 100%;
  background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-user-to-groups-modal-wrapper-card {
  .modal-wrapper-card-wrapper {
    .modal-wrapper-card {
      padding: 20px 30px;
    }
  }
}

.add-user-to-groups-modal-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.02em;
  font-size: 1.8rem;
  line-height: 2.2rem;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-weight: 600;
}

.add-user-to-groups-button {
  transition: opacity ease-in-out 0.3s;
  &-loading {
    opacity: 0.7;
    pointer-events: none;
    user-select: none;
  }
}
