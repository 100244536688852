@import '../../../../assets/styles/abstracts/variables';

.container {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px -20px 40px rgba(17, 29, 41, 0.6);
  padding: 3rem 0 7rem;
  border-radius: 4rem;
  display: none;
  z-index: 9;

  &.active {
    display: block;
  }
}

.section {
  padding-bottom: 1rem;
}
.sectionBottom {
  padding-top: 1.2rem;
}
.option {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.3rem 3rem 0.8rem 3rem;
  span {
    @include montserrat-14-semi-bold;
    color: #0d1444;
    margin-left: 1.4rem;
    white-space: nowrap;
  }
  &:hover {
    background-color: #f4f4f4;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  // img {
  //   margin-right: 1.4rem;
  //   width: 2rem;
  // }
}
.separator {
  height: 1px;
  background-color: #d9d9d9;
  margin: 0 3rem;
}
