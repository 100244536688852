@import '../../assets/styles/abstracts/variables';

.messages {
  &__stories {
    padding: 0;
    margin-bottom: 1rem;
  }

  &__chatcard {
    position: relative;
    display: flex;
    height: 8rem;
    padding: 0.7rem;
    background: $white89deg;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin-bottom: 1rem;

    &__avatar {
      margin-right: 0.6rem;
      img {
        height: 6.6rem;
        width: 6.6rem;
        border-radius: 50%;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: 1rem;
      word-break: break-word;
      &__name {
        @include montserrat-16-semi-bold;
        margin: 0;
        color: #0d1444;
        max-width: 80%;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        &__n {
          margin-right: 0.3rem;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        @media (max-width: 360px) {
          @include montserrat-12-bold;
        }
      }

      &__username {
        @include sf-12-normal;
        color: #828c94;
        margin: 0;

        @media (max-width: 360px) {
          @include sf-10-normal;
        }
      }

      &__message {
        @include sf-14-normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        margin: 0;
        color: #828c94;

        @media (max-width: 360px) {
          @include sf-10-normal;
        }
      }
    }

    &__stats {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__time {
        @include montserrat-12-normal;
        font-weight: 500;
        text-align: right;
        color: #b0b0b0;
        margin: 0 0 2rem 0;
        &_no_margin {
          margin: 0;
        }
      }

      &__number {
        @include flex-center;
        @include montserrat-10-bold;
        font-size: 1.1rem;
        color: #ffffff;
        background: $blue92deg;
        width: fit-content;
        padding: 0.5rem 0.75rem;
        border-radius: 50%;
        margin: 3rem 0 0 0;
        position: absolute;
        right: 1.1rem;
      }
    }
  }

  &__addNew {
    @include flex-center;

    position: fixed;
    bottom: 8rem;
    right: 2rem;

    border-radius: 50%;
    width: 6rem;
    height: 6rem;

    background: #369cfd;
    border: 3px solid #76bcff;
  }

  &__nomessages {
    margin-top: 8.8rem;
    position: relative;
    text-align: center;
    color: #0d1444;

    &--outer {
      filter: opacity(0.5);
    }

    &--inner {
      position: absolute;
      left: calc(50% - 4.5rem);
      top: 6.5rem;
    }

    &__title {
      @include montserrat-24-bold;
      margin: 2.8rem 0 0 0;
    }

    &__text {
      @include montserrat-14-normal;
      margin: 0;
    }
  }
}
