@import '../../../../assets/styles/abstracts/variables';

.startmodal {
  &__top {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--left {
      @include montserrat-14-semi-bold;
      color: #fff;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      span {
        display: block;
        margin-left: 1rem;
      }
    }
    &--right {
      @include montserrat-12-normal;
      color: #778797;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      span {
        display: block;
        margin-right: 1rem;
      }
    }
    &__iconwrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1.1rem 1.3rem;
      background: rgba(63, 68, 80, 0.5);
      border-radius: 1rem;
    }
  }
  &__mid {
    margin-bottom: 1rem;
  }
}

.availabilitySettings {
  &__searchbar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    &--sort {
      padding: 1.5rem;
      background: #262a33;
      border-radius: 1rem;
    }
    .searchfield {
      padding: 0 2rem 0 4rem;
      background: #262a33;
      border: 1px solid rgba(38, 42, 51, 0.2);
      box-sizing: border-box;
      border-radius: 1.5rem;
      flex: 1;
      margin-right: 1rem;

      input {
        @include montserrat-14-semi-bold;
        color: #fff;
        background: transparent;
        border: none;
        box-shadow: none;
        text-indent: 6%;
      }
    }
  }
  &__groups {
    &--divider {
      height: 1px;
      padding: 0 1rem;
      margin: 2rem 0;
      background: #282f3b;
    }

    &--bottom {
      height: 16.5rem;
      overflow: auto;
      padding: 0;
    }
  }
  &__group {
    position: relative;

    .checkboxField {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);

      &__box {
        margin-right: 0;
        min-width: 2rem;
        height: 2rem;

        &__check {
          width: 1rem;
          height: 1rem;
        }

        &--selected {
          border: 2px solid #fff;
        }
      }
    }
  }
  &__buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
  .tabnav {
    background: #262a33;
    width: fit-content;
    margin-bottom: 2rem;
  }
}

.box {
  background: #262a33;
  padding: 2.5rem 2rem;
  border-radius: 2.1rem;
}

.customTextarea {
  padding: 1.5rem 2rem;
  textarea {
    @include montserrat-14-semi-bold;
    background: transparent;
    border: none;
    width: 100%;
    color: #fff;

    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #778797;
    }
  }
}

.switchWithLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    @include montserrat-14-semi-bold;
    color: #fff;
  }
}

.sortmodal {
  .sortModal {
    &__sort {
      &__item {
        color: #fff;
      }
    }
  }
  .button {
    border: 2px solid #ffffff;
  }
}

.cameraSettings {
  &__list {
    max-height: 42rem;
    overflow-y: auto;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
  }
}

.camera {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.7rem 1rem 2.7rem 2rem;
  background: #262a33;
  border-radius: 2.1rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    &--text {
      @include montserrat-14-semi-bold;
      color: #fff;
      margin-left: 1.6rem;
    }
  }
}
.connectionSettings {
  &__list {
    max-height: 42rem;
    overflow-y: auto;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
  }
}

.connection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.7rem 1rem 2.7rem 2rem;
  background: #262a33;
  border-radius: 2.1rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    &--text {
      @include montserrat-14-semi-bold;
      color: #fff;
      margin-left: 1.6rem;
    }
  }
}
