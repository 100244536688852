.PhoneInput {
  position: relative;
  display: flex;
  align-items: center;
  height: 7rem;
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 1rem;
}

.PhoneInputCountry {
  position: absolute;
  min-width: 5rem;
  border-radius: 20px;
  top: 50%;
  transform: translate(0, -50%);
  left: 10px;
  height: 5rem;
  width: 5rem;
  background: #f5f5f6;
  align-items: center;
  display: flex;
  justify-content: center;
}
.PhoneInputCountryIcon {
  height: 2em;
  width: 2em;
}
.PhoneInputInput {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 600;
  text-indent: 1.8rem;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  border: 2px solid transparent;
  transition:
    border 0.2s,
    padding 0.2s;
  padding-left: 70px;
  text-indent: 0;
}
.PhoneInputInput::placeholder {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.02em;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
  left: 2rem;
  color: #b0b0b0;
  pointer-events: none;
  background-color: white;
  left: 70px;
}
.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}
.PhoneInputCountryIconImg {
  display: block;
  width: 100%;
  height: 100%;
}
