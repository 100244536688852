@import '../../../assets/styles/abstracts/variables';

.iconbutton {
  @include flex-center;
  height: 5rem;
  width: 5rem;
  background: #f5f5f6;
  border-radius: 1rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &--black {
    background: #262a33;
  }
  &--black--transparent {
    background: #262a3375;
    border: 1px solid #525967;
  }

  &--outline {
    background: #ffffff;
    border: 1px solid #f4f4f4;
  }
  &--white {
    background: #fff;
    box-shadow: 0px 4px 14px rgba(162, 162, 162, 0.2);
  }
  &--transparent {
    background: transparent;
  }
  &--lightGrey {
    background: rgba($color: #eaeaea, $alpha: 0.5);
  }

  &--disabled {
    background-color: #cccccc;
    opacity: 0.9;
    cursor: default;
    pointer-events: none;
  }

  &--small {
    height: 2.5rem;
    width: 2.5rem;
  }

  &--dropdown {
    height: 3rem;
    width: 3rem;
    padding: 0.8rem;
  }

  &--medium {
    height: 4rem;
    width: 4rem;
  }
}
