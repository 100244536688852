.layout_container {
  & :global(.whs__wrapper) {
    display: flex;
    flex-direction: column;
  }
}

.error_text {
  font-family: 'Montserrat', sans-serif;
  color: #0d1444;
  font-size: 28px;
  font-weight: 600;
}

.user_container {
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(17, 29, 41, 0.6);
  border-radius: 20px;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  padding: 35px 30px;
  * {
    font-family: inherit;
  }
}

.user_title {
  color: #0d1444;
  font-weight: 600;
  font-size: 18px;
}

.user_info {
  margin: 25px 0;
  display: flex;
}

.user_info_img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    border-radius: 100%;
  }
}

.user_info_names {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  color: #0d1444;
}

.username {
  color: #828c94;
  font-size: 12px;
}

.confirmation {
  font-size: 14px;
  color: #0d1444;
}

.buttons_container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.button {
  width: 98px;
  height: 36px;
  background: #f5f5f6;
  border-radius: 20px;
  color: #757576;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.yes_button {
  width: 151px;
  height: 36px;
  background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
  border-radius: 160px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
}

.loader_container {
  margin: auto;
}
