$cardSpacing: 6px;

.card_wrapper {
  position: relative;
  width: 10%;
  padding-top: 10%;
  @media (max-width: 1200px) {
    width: 16.6%;
    padding-top: 16.6%;
  }
  @media (max-width: 800px) {
    width: 25%;
    padding-top: 25%;
  }
  @media (max-width: 600px) {
    width: 33%;
    padding-top: 33%;
  }
  @media (max-width: 400px) {
    width: 50%;
    padding-top: 50%;
  }
  @media (max-width: 300px) {
    width: 100%;
    padding-top: 100%;
  }
}

.card_container {
  position: absolute;
  top: $cardSpacing;
  left: $cardSpacing;
  bottom: $cardSpacing;
  right: $cardSpacing;
  border-radius: 20px;
  overflow: hidden;
  border: 3px solid transparent;
  transition: all ease-in-out 0.3s;
  video {
    object-fit: cover;
    object-position: center;
    position: absolute;
  }
}

.card_container_selected {
  border: 3px solid #76bcff;
  filter: drop-shadow(0px 4px 14px rgba(47, 152, 254, 0.5));
}

.cirlce {
  width: 26px;
  height: 26px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #0d1444;
  z-index: 10;
}

.cirlce_selected {
  background-color: #ffffff;
}

.video_play {
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.media_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.media_tags_date_container {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0 4px;
  overflow: hidden;
  border-radius: 6px;
}

.media_tags_background {
  position: absolute;
  background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 6px;
  z-index: 0;
  opacity: 0.3;
}

.media_tags_date_text {
  font-family: 'Montserrat', sans-serif;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  z-index: 1;
}

.media_bottom_tags_container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media_bottom_tag {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 2.5px;
  padding: 1px 4px;
}

.media_bottom_tag_text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  z-index: 1;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  span {
    margin-left: 5px;
  }
}
