@import '../../assets/styles/abstracts/variables';

.mediaRecorder {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 12;
  // padding: 2rem;

  video {
    height: 100%;
  }

  &__card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: calc(100% - 4rem);
    margin: 0 auto;
    border-radius: 2rem;
    padding: 4rem 2rem;
  }

  &__error {
    @include montserrat-14-normal;
    color: #000;
  }

  &__audio {
    .mediaRecorder__controls {
      background-color: #fff;
      width: calc(100% - 4rem);
      margin: 0 auto;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;
    z-index: 3;
    &__title {
      @include montserrat-18-semi-bold;
      color: #fff;
      margin: 0;
    }
    &__close {
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 2rem;
    width: 100%;
    border-radius: 2rem;
    padding: 2rem;

    &__duration {
      @include montserrat-16-semi-bold;
      color: #200e32;
      text-align: center;
      margin-bottom: 2rem;
    }

    &__actions {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &__wrapper {
        width: 6rem;
        height: 6rem;
        border: 3px solid gray;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.recording {
          background-color: rgba(51, 153, 255, 0.1);
          padding: 2px;
        }
      }

      &--settings {
        position: absolute;
        left: 0;
      }

      &--record {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        background-color: red;
      }
      &--stop {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 3px;
        background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
      }
    }
  }
}
