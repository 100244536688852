@import '../../../../assets/styles/abstracts/variables';

.bubblesContainer {
  display: flex;
  margin-bottom: 1.6rem;

  .bubble {
    background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
    border: 1px solid #dddddd;
    border-radius: 20px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 1.6rem 2.1rem;
    width: 100%;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    .bubbleTitle {
      @include montserrat-12-light;
      color: #b0b0b0;
      margin: 0;
    }

    .bubbleSubtitle {
      @include montserrat-12-semi-bold;
      color: #0d1444;
      margin: 0;
    }
  }
}

.actionContainer {
  .action {
    @include montserrat-12-semi-bold;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    color: #0d1444;
    display: flex;
    line-height: 350%;
    margin-bottom: 0.7rem;
    text-decoration: none;
    &:last-child {
      border-bottom: transparent;
      margin-bottom: 0;
    }

    span {
      margin-left: 1.3rem;
    }
  }
}
