@import '../../../assets/styles/abstracts/variables';
.previewActions {
  width: 5.6rem;
  border-radius: 28px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 7.1rem 1.6rem 1.6rem 1.6rem;
  position: absolute;
  right: 2rem;
  top: 7.9rem;
  z-index: 7;

  &__avatar {
    border: 2px solid #2e97fe;
    border-radius: 50%;
    width: 5.6rem;
    height: 5.6rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &--ring {
      width: 4.7rem;
      height: 4.7rem;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
}

.previewAction {
  @include montserrat-12-normal;
  line-height: 1.8rem;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 1.1rem;
  }
}
