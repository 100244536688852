@import '../../../../assets/styles/abstracts/variables';

.editImageModal {
  display: flex;
  justify-content: space-between;

  &__crop {
    width: 100%;

    &__editimage {
      .cropper-view-box {
        border-radius: 0;
      }
    }
  }

  &__block {
    @include flex-center;
    width: 100%;
    height: 30rem;
    background-color: #000;
    border: 3px solid #fff;
    border-radius: 2rem;
    margin-bottom: 2rem;
    overflow: hidden;

    &--img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    &__action {
      position: relative !important;

      .iic--modal__container {
        img {
          filter: brightness(0);
        }
      }
    }
  }
}
