@import '../../assets/styles/abstracts/variables';

.postContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  :global(.minimize__icon) {
    display: none;
  }

  .close {
    position: fixed;
    padding: 1rem;
    top: 2rem;
    right: 1rem;
    z-index: 101;

    svg {
      filter: drop-shadow(0.1rem 0.1rem 0.2rem #00000066);
    }
  }
}
