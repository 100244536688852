.container {
  position: fixed;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  transform: translate(0, 100%);
  opacity: 0;
  visibility: hidden;
  transition:
    transform ease-in-out 0.3s,
    opacity ease-in-out 0.3s,
    visibility ease-in-out 0.3s;
  padding: 20px;
}

.container_open {
  transform: translate(0, 0);
  visibility: visible;
  opacity: 1;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  color: #262a33;
  & :global(.header__linkback) {
    &::after {
      background-color: #262a33;
    }
    &::before {
      background-color: #262a33;
    }
  }
}

.header {
  padding: 20px 0;
}

.separator {
  height: 1px;
  margin: 20px;
  margin-top: 0;
  background-color: #d9d9d9;
}

.add_to_buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  z-index: 10;
}

.add_to_button {
  width: 128px;
  height: 35px;
  margin-right: 10px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.cards_container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-bottom: 95px;
}

.button_loading {
  cursor: default;
  pointer-events: none;
  user-select: none;
  opacity: 0.7;
}

.cards_types_container {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f5f5f6;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.card_type {
  flex: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: #757576;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  border-radius: 20px;
  &_selected {
    background: #262a33;
    color: #fff;
  }
  @media (max-width: 360px) {
    font-size: 10px;
  }
  @media (max-width: 320px) {
    font-size: 9px;
  }
}
