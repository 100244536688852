@import '../../../assets/styles/abstracts/variables';

.icon {
  position: absolute;
  width: 3rem;
  height: 3rem;
  border: 1px solid rgba(255, 255, 255, 0.49);
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
}

.picker {
  position: absolute;
  bottom: -0.5rem;
  left: 13rem;
  display: flex;
  width: fit-content;
  background: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  border-radius: 1rem;
}

.pickerItem {
  position: relative;
  width: 3rem;
  height: 3rem;
  border: 1px solid rgba(255, 255, 255, 0.49);
  box-sizing: border-box;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  z-index: 2;

  &:not(:last-of-type) {
    margin-right: 1rem;
  }
}
