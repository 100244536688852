@import '../../../assets/styles/abstracts/variables';

.button {
  @include flex-center;
  position: relative;
  border: none;
  border-radius: 160px;
  border: 2px solid transparent;
  cursor: pointer;
  // * COLOR
  &--blue {
    background: $blue92deg;
    color: #fff;
    box-shadow: 0px 10px 10px rgba(78, 172, 249, 0.2);
  }
  &--black {
    background: $black157deg;
    color: #fff;
  }
  &--transparent {
    background: transparent;
  }
  &--grey {
    background: #f5f5f6;
    color: #757576;
  }
  &--dark-grey {
    background: #262a33;
    color: #757576;
  }
  &--white {
    background: #fff;
    color: #0d1444;
  }
  &--green {
    background: #e9f3e7;
    color: #78a56d;
  }

  // * TYPES
  &--transparent {
    &--white {
      color: #fff;
      border: 2px solid #fff;
    }
    &--dark {
      color: #0d1444;
      border: 2px solid #0d1444;
    }
    &--black {
      color: #131318;
      border: 2px solid #131318;
    }
    &--borderless {
      color: #757576;
    }
    &--blue {
      color: #fff;
      border: 2px solid #2f98fe;
    }
    &--black1px {
      color: #131318;
      border: 1px solid #000000;
    }
    &--dark1px {
      color: #757576;
      border: 1px solid rgba(103, 110, 125, 0.8);
    }
    &--lightblue {
      color: #3199fe;
      border: 1px solid #b4daff;
      border-radius: 5px;
    }
    &--cyan {
      color: #10cbbb;
      border: 1px solid #a1e7e1;
      border-radius: 5px;
    }
    &--login {
      border: 2px solid rgba(47, 152, 254, 0.8);
      border-radius: 25px;
    }
  }

  // * FONT
  &--mont-10-semi-bold {
    @include montserrat-10-semi-bold;
  }
  &--mont-11-normal {
    @include montserrat-11-normal;
  }
  &--mont-14-normal {
    @include montserrat-14-normal;
    font-weight: 500;
  }
  &--mont-14-bold {
    @include montserrat-14-bold;
  }
  &--mont-14-semi-bold {
    @include montserrat-14-semi-bold;
  }
  &--mont-16-bold {
    @include montserrat-16-bold;
  }
  &--mont-16-semi-bold {
    @include montserrat-16-semi-bold;
  }
  &--mont-18-semi-bold {
    @include montserrat-18-semi-bold;
  }
  &--mont-18-bold {
    @include montserrat-18-bold;
  }
  &--sf-16-normal {
    @include sf-16-normal;
  }

  // * HEIGHT
  &--height-6 {
    height: 6rem;
  }
  &--height-5 {
    height: 5rem;
  }
  &--height-4 {
    height: 4rem;
  }
  &--height-3 {
    height: 3.6rem;
  }
  &--height-2 {
    height: 2rem;
  }

  // * WIDTH
  &--width-100 {
    width: 100%;
  }
  &--width-20 {
    width: 20rem;
  }
  &--width-16 {
    width: 16rem;
  }
  &--width-15 {
    width: 15rem;
  }
  &--width-14 {
    width: 14rem;
  }
  &--width-13 {
    width: 13rem;
  }
  &--width-12 {
    width: 12.5rem;
  }
  &--width-11 {
    width: 11rem;
  }
  &--width-10 {
    width: 10rem;
  }
  &--width-7 {
    width: 7rem;
  }
  &--width-6 {
    width: 6rem;
  }
  &--width-fit {
    width: fit-content;
  }
  &--width-max-fit {
    width: max-content;
  }

  // * PADDING
  &--padding-05 {
    padding: 0.5rem;
  }
  &--padding-1 {
    padding: 1rem;
  }
  &--padding-15 {
    padding: 1.5rem;
  }
  &--padding-1-15 {
    padding: 1rem 1.5rem;
  }
  &--padding-2 {
    padding: 0 2rem;
  }
  &--padding-3 {
    padding: 0 3rem;
  }
  &--padding-4 {
    padding: 0 4rem;
  }
  &--padding-5 {
    padding: 0 5rem;
  }

  // * DISABLED
  &--disabled {
    opacity: 0.3;
  }

  // * MISC
  &__circleicon {
    @include flex-center;

    position: absolute;
    top: -2x;
    left: -1px;

    border: 1px solid #2f98fe;
    border-radius: 50%;
    height: 5rem;
    width: 5rem;

    &--twitter {
      background: $blue92deg;
      margin-left: -1px;
      img {
        filter: brightness(10);
      }
    }

    &--google {
      background-color: transparent;
      border: 3px solid;
      border-color: #ea4335 #fbbc04 #4285f4 #34a853;
      transform: rotateZ(315deg);
      margin-left: -1px;
      img {
        transform: rotateZ(45deg);
      }
    }

    &--small {
      position: relative;
      margin-right: 1.3rem;
      border: none;
      width: auto;
    }

    img {
      height: 2rem;
    }
  }

  &__icon {
    margin-right: 1rem;

    &--prev {
      margin-right: 1.5rem;
    }
    &--next {
      margin-left: 1.5rem;
    }
  }
}
