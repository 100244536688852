.container {
  background: linear-gradient(180deg, #ffffff 12.4%, #fbfbfb 99.26%);
  border-radius: 2rem;
  padding: 2rem 2rem 0 2rem;
  margin: 0 -2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.separator {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 2rem;
}
