@import '../../../assets/styles/abstracts/variables';

.story {
  min-width: 5.8rem;
  height: 7.5rem;
  text-align: center;

  &--img {
    margin-right: 1rem;
  }

  &__circle {
    @include flex-center;
    width: 5.8rem;
    height: 5.8rem;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid #2e97fe;

    &--viewed {
      border: 2px solid rgba(232, 232, 232, 1);
    }

    img {
      height: 5rem;
      width: 5rem;
      object-fit: cover;
      border-radius: 50%;
      opacity: 0.75;
    }
  }

  &__add {
    border: 2px dashed #75757680;

    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  &__text {
    @include montserrat-10-normal;
    margin: 0.5rem 0 0 0;
    color: #757576;
    text-decoration: none;
  }
}
