@import '../../../../../../assets/styles/abstracts/variables';

.duration {
  @include montserrat-12-normal;
  font-weight: 500;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 0.2rem 0.8rem;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(157.59deg, hsla(233, 14%, 11%, 0.3) 0%, rgba(15, 15, 19, 0.3) 100%);
}

.controls {
  position: absolute;
  top: calc(50% - 1rem);
  height: 2rem;
  width: 2rem;
  left: calc(50% - 1rem);
}
