.header-right {
  display: none;
  height: 6rem;
  min-width: 32rem;
  margin-top: 0;
  justify-content: end;
  gap: 3rem;
  align-items: center;
  .header-icon {
    filter: invert(1);
    height: 4rem;
    display: flex;
    align-items: center;
  }

  a:last-of-type {
    margin-right: 1.5rem;
    padding-right: 1.5rem;
    border-right: solid 0.1rem #c4c4c4;
  }
  .header-avatar {
    border-radius: 50%;
    height: 4rem !important;
    width: 4rem;
    object-fit: cover;
  }
  .dropdown-button {
    background-color: #f1f1f1;
    padding: 0.9rem;
    border-radius: 1rem;
  }
}
@media (min-width: 680px) {
  .header-right {
    display: flex;
  }
}
