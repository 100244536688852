@import '../../../assets/styles/abstracts/variables';

.csTabs {
  @include montserrat-12-semi-bold;
  position: relative;
  display: flex;
  width: 100%;
  height: 4rem;
  background: #f5f5f6;
  color: #757576;
  border-radius: 20px;
  padding: 0.5rem 1rem;

  &--full {
    width: 100%;

    .csTabs__tab {
      @include flex-center;
      flex: 1;
    }
  }

  &--fit {
    width: fit-content;

    .csTabs__tab {
      @include flex-center;
      flex: 1;
    }
  }

  &__tab {
    min-width: fit-content;
    padding: 0 1.5rem;
  }

  &__tab--active {
    background: #262a33;
    color: #fff;
    border-radius: 20px;
  }

  &__infocircle {
    @include flex-center;
    @include montserrat-10-bold;
    position: absolute;
    top: 0;
    right: 0;
    height: 2rem;
    width: 2rem;
    background: $blue92deg;
    border-radius: 50%;
    color: #ffffff;
  }
}
