@import '../../../../assets/styles/abstracts/variables';

.deleteAccountModal {
  &__description {
    @include montserrat-14-normal;
    color: #757576;
    margin-bottom: 1.5rem;

    &--bot {
      border-top: 1px solid #d9d9d9;
      padding-top: 2.4rem;
    }
  }

  &__reason {
    @include montserrat-12-bold;
    color: #349bfd;
    overflow: hidden;
    transition: 0.4s height;
    margin-bottom: 1.5rem;

    &--inactive {
      height: 2.5rem;
    }
    &--active {
      height: 8rem;
    }

    p {
      margin: 0 0 1.5rem 0;
      display: inline-block;
    }

    &__input {
      width: 100%;
      height: 5rem !important;
      border-radius: 15px !important;

      input,
      textarea {
        border-radius: 15px !important;
      }
      .inputcard__label {
        top: 1.6rem !important;
      }
      .inputcard__label--filled {
        top: 0.6rem !important;
      }
    }
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;

    .button:first-of-type {
      margin-right: 0.5rem;
    }
    .button:last-of-type {
      margin-left: 0.5rem;
    }
  }
  &__button {
    flex: 1;
    @include montserrat-14-normal;
    font-weight: 500;
    height: 3.6rem;
    border-radius: 160px;
    border: 1px solid transparent;
    outline: none;

    &:focus {
      outline: none;
    }

    &:first-of-type {
      margin-right: 0.5rem;
    }
    &:last-of-type {
      margin-left: 0.5rem;
    }

    &--white {
      border: 1px solid #000000;
      background: transparent;
      color: #131318;
      opacity: 0.5;
    }

    &--grey {
      background: $black157deg;
      opacity: 0.5;
      color: #fff;
    }

    &--deactivate {
      @include montserrat-16-bold;
      max-width: 65%;
      height: 5rem;
      color: #fff;
      background: $black157deg;
      opacity: 0.3;
      border-radius: 25px;
    }
  }
}
