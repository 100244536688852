@import '../../../assets/styles/abstracts/variables';

.navbar {
  &--dark {
    background-color: #262a33;

    &__link {
      &--inactive {
        color: #757576;
      }
      &--active {
        background: #fff;
        color: #0d1444;
      }
    }
  }

  &--light {
    background-color: #f5f5f6;

    &__link {
      &--inactive {
        color: #757576;
      }

      &--active {
        background: #262a33;
        color: #fff;
      }
    }
  }

  padding: 0.5rem;
  height: 4rem;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &__link {
    @include flex-center;
    @include montserrat-12-semi-bold;
    text-decoration: none;
    height: 100%;
    text-transform: capitalize;
    border-radius: 20px;
    -webkit-tap-highlight-color: transparent;
    width: fit-content;
    padding: 0 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__header {
    position: absolute;
    bottom: 2rem;
    background-color: #262a33;
    width: fit-content;
    max-width: 100%;
  }

  &__feed {
    width: fit-content;
    max-width: 100%;

    a {
      width: fit-content;
      padding: 0 2rem;
    }
  }
}
