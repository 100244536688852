@import '../../../../assets/styles/abstracts/variables';

.bubblesContainer {
  display: flex;
  margin-bottom: 1.6rem;
}

.streamInfo {
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  border: 1px solid #dddddd;
  border-radius: 2rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  height: 7rem;
  margin-top: 2rem;
  width: 100%;
}

.uploadBackground {
  align-items: center;
  background: linear-gradient(140.46deg, rgba(#93ceff, 0.1) 14.38%, rgba(#2894ff, 0.1) 94.98%);
  background-position: center;
  background-size: cover;
  border-radius: 2rem;
  display: flex;
  justify-content: center;
  height: 16rem;
  position: relative;
  width: 100%;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .uploadButton {
    align-items: center;
    cursor: pointer;
    display: flex;
    background: linear-gradient(140.46deg, #93ceff 14.38%, #2894ff 94.98%);
    border-radius: 2rem;
    justify-content: center;
    height: 6rem;
    width: 6rem;
    input[type='file'] {
      height: 1px;
      opacity: 0;
      position: absolute;
      width: 1px;
    }
    .iconContainer {
      align-items: center;
      border: 1.5px solid #ffffff;
      border-radius: 1rem;
      color: #ffffff;
      display: flex;
      font-size: 2rem;
      font-weight: 600;
      justify-content: center;
      height: 3rem;
      width: 3rem;
    }
  }
  .infoMessage {
    @include montserrat-14-light;
    color: #15161c;
    margin-bottom: 0;
  }
}
.imageIcon {
  align-items: center;
  background: linear-gradient(157.59deg, rgba(#181920, 0.1) 0%, rgba(#0f0f13, 0.1) 100%);
  bottom: 1rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  height: 3.6rem;
  left: 1rem;
  position: absolute;
  width: 3.6rem;
}

.buttonsContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
