.search_wrapper {
  position: relative;
}

.search_field :global(.searchfield__input) {
  transition: all ease-in-out 0.3s;
}

.search_field_open :global(.searchfield__input) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.search_suggestions_container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 295px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid rgba(38, 42, 51, 0.2);
  border-radius: 0px 0px 15px 15px;
  z-index: 10;
  overflow-y: auto;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
  &_open {
    opacity: 1;
    visibility: visible;
  }
  * {
    font-family: inherit;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.search_suggestions_title_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search_suggestions_title {
  font-weight: 600;
  font-size: 14px;
  color: #0d1444;
}

.search_suggestions_clear {
  cursor: pointer;
  font-size: 12px;
  color: #2894ff;
  user-select: none;
}

.search_suggestions_separator {
  width: 100%;
  background: #d9d9d9;
  height: 1px;
  margin-top: 17px;
  margin-bottom: 20px;
}

.search_suggestion {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.search_suggestions_username_container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: auto;
}

.search_suggestions_name {
  font-weight: 600;
  font-size: 14px;
  color: #0d1444;
}

.search_suggestions_username {
  font-size: 12px;
  color: #828c94;
}

.search_suggestions_remove_button {
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform ease-in-out 0.3s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(1.1);
    outline: none;
  }
}
