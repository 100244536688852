@import '../../../../assets/styles/abstracts/variables';

.friendReqModal {
  &__message {
    p {
      @include montserrat-12-bold;
      color: #349bfd;

      &.inputShown {
        color: #828c94;
      }
    }
    textarea,
    .textarea {
      @include montserrat-14-normal;
      font-weight: 500;
      color: #0d1444;
      width: 100%;
      height: 90px;
      background: #fefefe;
      border: 1px solid rgba(38, 42, 51, 0.2);
      border-radius: 15px;
      padding: 1rem;
      margin-bottom: 2.5rem;
      overflow: hidden;
      display: block;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    &__sent {
      p {
        @include montserrat-14-normal;
        font-weight: 500;
        color: #0d1444;
        background: #f6f6f6;
        border-radius: 15px;
        padding: 1rem;
        word-break: break-word;
      }

      &__confirm {
        @include montserrat-14-normal;
        font-weight: 500;
        color: #0d1444;
        display: flex;
        align-items: center;

        img {
          border-radius: 50%;
          padding: 1.5rem;
          margin-right: 1.3rem;
        }

        .icon-wrapper {
          padding-top: 25px;
        }
      }

      margin-bottom: 2.5rem;
    }
  }

  &__button {
    float: right;

    &--sent {
      margin: 0 auto;
    }
  }
}
