@import '../../assets/styles/abstracts/variables';

.settings {
  &__content__wrapper {
    height: calc(100% - 12rem);
    overflow-y: hidden;
  }

  .settingscard {
    margin-bottom: 1rem;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    &__break {
      margin: 2rem 0;
      color: #d9d9d9;
      opacity: 0.5;
    }
  }

  &__addlanguage {
    position: fixed;
    bottom: 10rem;
    left: calc(50% - 10rem);
  }

  &__filtered {
    h2 {
      @include montserrat-16-bold;
      color: #828c94;
    }
  }
}

.settings-search-no-results {
  @include montserrat-16-bold;
  color: #828c94;
  font-size: 18px;
}

.singlesetting {
  @include flex-center;
  height: 100%;
  border-radius: 25px 25px;
  background: $white90deg;

  &__section {
    @include montserrat-16-normal;
    margin: 0 !important;
    text-transform: capitalize;
    color: #828c94;
    padding-bottom: 0.8rem;

    &__title {
      @include montserrat-24-normal;
      display: flex;
      justify-content: space-between;
      margin: 0 !important;
      text-transform: capitalize;
      color: #0d1444;
    }
  }

  &__subsection {
    @include montserrat-18-bold;
    margin: 3rem 0 1.5rem 1rem;
    color: #0d1444;
  }

  &__form {
    padding: 2rem;
    padding-left: 0;
    width: 100%;

    &__input {
      input {
        border-radius: 10px !important;
        border: none;
      }
    }

    &__button {
      height: 4rem !important;
      width: 20rem;
      position: fixed;
      bottom: 10rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__notifications {
    position: relative;
    width: 100%;
    height: 5rem;
    background: $white89deg;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    margin-bottom: 1rem;

    label {
      @include montserrat-14-bold;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      padding: 2rem;
      color: #0d1444;
    }

    &__checkbox {
      @include flex-center;
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      height: 2rem;
      width: 2rem;
      border: 1.51px solid rgba(185, 193, 210, 0.8);
      border-radius: 25px;

      &--check {
        height: 1rem;
        width: 1rem;
        border-radius: 25px;
        background: #2f98fe;
        transform: scale(0);
        transition: 0.2s transform;

        &--checked {
          transform: scale(1);
        }
      }
    }
  }

  &__loginsession {
    &__card {
      display: flex;
      width: 100%;
      background-color: #fff;
      border-radius: 25px;
      padding: 1rem;
      position: relative;
      margin-bottom: 1rem;
    }
    &__info {
      flex: 1;

      &__device {
        display: flex;
        margin: 0.5rem 0;
        width: 70%;

        font-family: $montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.7rem;
        margin-bottom: 1rem;

        color: #0d1444;

        img {
          height: 1.6rem;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      &__location {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5rem 0 1rem 0;

        font-family: $montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;

        color: #b0b0b0;

        span {
          display: flex;
          align-items: center;
        }

        img {
          height: 1.6rem;
          width: 1.6rem;
          margin: 0 0.5rem 0 0.8rem;
        }
      }
    }
    &__endsessions {
      position: fixed;
      left: calc(50% - 12rem);
      bottom: 8rem;

      img {
        margin-right: 1rem;
      }
    }
  }

  &__autotwitter {
    height: 7rem;
  }
}

.editAccountInfo {
  width: 100%;
  height: 7rem;
  background: $white89deg;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;

  &__image {
    min-width: 4.55rem;
    height: 4.55rem;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 1.1rem;
    margin-right: 1.4rem;
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px #2e97fe;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__user {
    &--p {
      @include montserrat-12-normal;
      font-weight: 500;
      color: #b0b0b0;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    &__username {
      @include montserrat-14-bold;
      color: #0d1444;
      margin: 0;
      margin-top: 0.5rem;
    }
  }
}

.settingscard__button {
  width: 9.3rem;
  height: 2.6rem;
  background-color: #ffffff;
  border: 1px solid rgba(103, 110, 125, 0.8);
  outline: none;
  border-radius: 2.5rem;
  font-family: $montserrat;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: #757576;
  position: absolute;
  right: 1rem;
  top: 2rem;
}
