@import '../../../../assets/styles/abstracts/variables';

.endmodal {
  &__note {
    @include montserrat-14-light;
    color: #778797;
    max-width: 24.2rem;
    margin: 0 0 2.2rem 0;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  &__viewer {
    &--icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--note {
      @include montserrat-18-semi-bold;
      color: #fff;
      line-height: 22px;
    }
  }
}

.livemodal--endmodal {
  .livemodal__content {
    height: 100%;
    position: relative;
    .endmodal__viewer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--button {
        position: absolute;
        bottom: 3rem;
      }
      &--icon {
        filter: grayscale(1);
      }
    }
  }
}
