@import '../assets/styles/abstracts/variables';

.modal {
  @include flex-center;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 101;
  transition: 0.4s opacity;

  &--inactive {
    height: 0;
    width: 0;
    user-select: none;
    opacity: 0;
  }

  &--active {
    opacity: 1;
  }

  &--withScroll {
    position: absolute;
    height: auto;
  }
  &.fullScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    :not(.modal__uploadImages) {
      .modal__card {
        background: $white180deg;
        width: calc(100% - 4rem);
        min-height: unset;
        padding: 2rem 3rem;
        border-radius: 20px;
        // position: relative;
        .modal__title {
          img {
            right: 0;
          }
        }

        &__withoutTitle {
          padding: 0;
          position: relative;

          &__close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            z-index: 1;
          }
        }
      }
    }
    .modal__card {
      width: 100%;
      min-height: 100%;
      border-radius: unset;
      padding: 3.2rem 0 6rem;

      .modal__title {
        margin-top: 0;
        margin-bottom: 0;
        img {
          right: 2.4rem;
          top: 0;
        }
      }
    }

    > .modal__card {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    @include montserrat-18-bold;
    position: relative;
    margin-bottom: 2.5rem;
    margin-top: 2rem;
    font-weight: 600;
    img {
      position: absolute;
      right: 0;
      top: -1rem;
    }
  }

  &__card {
    background: $white180deg;
    width: calc(100% - 4rem);
    padding: 2rem 3rem;
    border-radius: 20px;
    max-height: 90%;
    overflow-y: auto;
    &__withoutTitle {
      padding: 0;
      position: relative;

      &__close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 1;
      }
    }
  }

  &__icons {
    display: flex;
    justify-content: space-between;
    margin-top: 2.5rem;
  }

  &__option {
    width: 45%;

    &--img {
      @include flex-center;
      height: 10rem;
      width: 100%;
      background: $white180deg;
      border: 3px solid #ffffff;
      border-radius: 20px;
    }

    &--p {
      @include montserrat-12-bold;
      color: #757576;
      text-align: center;
    }
  }

  &__scrollblock {
    overflow-y: hidden !important;
  }

  &.no-close {
    .modal__card__withoutTitle {
      padding: 0;
      position: relative;

      &__close {
        display: none;
      }
    }
  }
}

.previewModal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #000;
  height: 100%;

  &--inactive {
    height: 0;
    width: 0;
    user-select: none;
    opacity: 0;
    display: none;
  }

  &--active {
    opacity: 1;
  }

  &--minimized {
    top: unset;
    bottom: 0;
    height: 17rem;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: 1.5rem;
    z-index: 6;

    &.video {
      height: 18rem;
    }
  }

  &__close {
    position: absolute;
    top: 3rem;
    right: 2rem;
  }
}
.newList {
  .modal__card {
    background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
    box-shadow: 0px 20px 40px rgba(17, 29, 41, 0.6);
    position: relative;
    padding: 2rem 2rem 9rem 2rem;
    overflow: hidden;
  }
}
