@import '../../../../assets/styles//abstracts/variables';

.accountsettings__card {
  display: flex;
  align-items: center;
  position: relative;
  height: 7rem;
  background: $white89deg;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 1rem;
  margin-bottom: 1rem;

  &__iic {
    position: relative;
    height: 5rem;
    width: 5rem;
    margin-right: 1.5rem;

    .iic--profile--small__container {
      width: 5rem;
      height: 5rem;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &__section {
      @include montserrat-12-normal;
      font-weight: 500;
      color: #b0b0b0;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    &__data {
      @include montserrat-14-bold;
      color: #0d1444;
      margin: 0.5rem 0 0;
    }

    &__input {
      @include montserrat-14-bold;
      border: none;
      color: #0d1444;
      padding: 0.5rem 0;
      margin-left: 1rem;

      &__section {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }

      &:focus {
        outline: none;
      }

      &::placeholder {
        @include montserrat-12-normal;
        font-weight: 500;
        color: #b0b0b0;
      }
    }
  }

  &__fixedbottombtn {
    @include montserrat-16-bold;
    text-align: center;
    color: #ffffff;
    width: 20rem;
    left: calc(50% - 10rem);
    display: flex;
    justify-content: center;

    img {
      display: none;
    }
  }

  &__error {
    text-align: center;
    p {
      @include montserrat-12-normal;
      margin: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: #ffdada;
      border-radius: 5px;
      height: 2.4rem;
      padding: 0 1rem;

      font-weight: 500;
      color: #e73939;
    }
  }

  &__input {
    padding: 0;

    input {
      height: 100%;
      width: 100%;
      border-radius: 20px;
      border: none;
      text-indent: 2rem;
      border: 2px solid transparent;

      &:focus {
        outline: none;
        border: 2px solid #2f98fe;
      }

      &::placeholder {
        @include montserrat-12-normal;
        font-weight: 500;
        color: #b0b0b0;
      }
    }
  }
}

//Connect Another Account

.connect_another_account_container {
  display: flex;
  flex: 1;
  .whs {
    height: auto;
    .whs__wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }
}

.connect_another_account_profile_placeholder {
  svg {
    transform: scale(0.9);
  }
}

.connect_another_account_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  font-size: 16px;
  max-width: 200px;
  width: 100%;
  height: 50px;
  user-select: none;
  transition: opacity ease-in-out 0.2s;
  &_disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.3;
  }
  &_modal {
    margin: 0 auto;
    margin-top: auto;
  }
}

.connect_another_account_modal {
  position: absolute;
  top: 68px;
  left: 20px;
  right: 20px;
  padding: 20px 20px 35px 20px;
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(17, 29, 41, 0.6);
  border-radius: 20px;
  transition: all ease-in-out 0.2s;
  min-height: 380px;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  margin-top: -15px;
  display: flex;
  flex-direction: column;
  &_open {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  &_title {
    @include montserrat-normal;
    margin-bottom: 18px;
    color: #0d1444;
    font-size: 18px;
    font-weight: 600;
  }
  &_desc {
    @include montserrat-normal;
    margin-bottom: 18px;
    color: #757576;
    font-size: 14px;
  }
  &_error {
    @include montserrat-12-normal;
    color: red;
    margin-bottom: 1rem;
  }
}

.connect_another_account_close {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
