.whs {
  :global {
    .actionCard {
      padding: 1rem 2rem 1rem 1rem;
      border: unset;
    }
  }
}

.nextPayout {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #757576;
  margin: 1.4rem 1rem 1.7rem;

  span {
    font-weight: 500;
    color: #0d1444;
  }
}
