@import '../../../../../assets/styles/abstracts/variables';

.bubble {
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  border: 1px solid #dddddd;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 1.4rem 1.9rem;
  width: 100%;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  .bubbleTitle {
    @include montserrat-12-light;
    color: #b0b0b0;
    margin: 0;
  }

  .bubbleSubtitle {
    @include montserrat-12-semi-bold;
    color: #0d1444;
    margin: 0;
  }
}
