@import '../../../../assets/styles/abstracts/variables';

.container {
  @include montserrat-12-normal;
  background: rgba(#000000, 0.5);
  border-radius: 20px;
  color: #828c94;
  padding: 0.6rem 1.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  span {
    color: #2894ff;
    margin-right: 0.7rem;
    margin-left: 0.2rem;
  }
}
