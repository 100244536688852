@import '../../../../../../assets/styles/abstracts/variables';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(140.46deg, #6b7e8d 14.38%, #2894ff 94.98%);
    opacity: 0.1;
    border-radius: 2rem;
  }
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  border-radius: 2rem;
  width: 100%;
  padding-right: 1rem;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
    opacity: 0.6;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 2rem;
  }

  .audiomessage {
    z-index: 2;
    height: auto;
  }
}
