@import '../../../../assets/styles/abstracts/variables';

.wrapper {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
  width: 11rem;
  height: 100%;
  padding: 1rem;
}
.link {
  width: 100%;
  height: 100%;
  background: rgba($color: #fff, $alpha: 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  text-decoration: none;
}

.icon {
  margin-bottom: 0.7rem;
}

.text {
  @include montserrat-12-semi-bold;
  color: #c1cbd6;
}
