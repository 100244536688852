@import '../../assets/styles/abstracts/variables';
.processingError {
  position: relative;
  width: 11.5rem;
  height: 11.5rem;

  &__abort {
    position: absolute;
    z-index: 2;
    top: -0.5rem;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    border-radius: 2rem;
    background: linear-gradient(140.46deg, #6b7e8d 14.38%, #2894ff 94.98%);
    opacity: 0.1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 1.8rem;
  }

  &__text {
    @include montserrat-12-light;
    position: absolute;
    top: 50%;
    padding: 0.5rem;
    text-align: center;
    color: red;
    font-size: 12px;
    transform: translateY(-50%);
    line-height: 1.8rem;
  }
}
