@import '../../../assets/styles/abstracts/variables';

.preview__comments {
  width: 100%;
  height: 60%;
  max-height: 60rem;
  background: $white180deg;
  border-radius: 20px 20px 0 0;
  transform: translateY(100%);
  transition: 0.3s all;
  z-index: 10;
  padding: 2rem 0;
  opacity: 0;
  pointer-events: none;

  &__wrapper {
    &--active {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 6;
      display: flex;
      align-items: end;
    }
  }

  &--active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
  }

  &__container {
    height: 100%;
    padding-left: 1rem;
    padding-bottom: 10rem;
    overflow-y: auto;
  }

  &__title {
    padding-right: 1.2rem;
    margin-bottom: 1.7rem;

    &--top {
      @include montserrat-16-normal;
      color: #0d1444;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    p {
      flex: 1;
      text-align: left;
      margin: 0 0 1rem 2rem;
    }

    span {
      @include montserrat-12-normal;
      color: #778797;
      display: block;
      text-align: center;
    }

    img {
      filter: brightness(0.5);
      height: 1.4rem;
      width: 1.4rem !important;
    }
  }

  &__add {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 2rem;
    background: #fff;
    border-radius: 4rem 4rem 0 0;
    z-index: 2;
  }

  &__input {
    position: relative;
    width: calc(100% - 7rem);
    height: 5rem;
    background: #fffcfc;
    border: 1px solid rgba(38, 42, 51, 0.2);
    border-radius: 15px;
    padding: 0.1rem;

    &--replyto {
      @include montserrat-14-semi-bold;
      color: #2894ff;
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 1rem;
      height: 100%;
      z-index: 26;
    }

    .emojiPicker {
      z-index: 1;
      margin-bottom: 1rem;
    }

    input {
      @include montserrat-14-normal;
      padding: 1rem 4rem 1rem 1rem;
      font-weight: 500;
      width: 100%;
      height: 100%;
      border: none;
      color: #0d1444;
      position: relative;
      border-radius: 15px;

      &:focus {
        outline: none;
      }
    }

    &__emoji {
      position: absolute;
      right: 1rem;
      top: 1rem;
      height: 2.6rem;
      width: 2.6rem !important;
    }
  }

  &__send {
    @include flex-center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: $blue92deg;
    border: 3px solid #76bcff;

    img {
      width: 2rem !important;
    }
  }
}

.preview__nocomments {
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    @include montserrat-24-semi-bold;
    color: #cfd0da;
    margin: auto;
    transform: translateY(-40px);
  }
}
