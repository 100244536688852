@import '../../../assets/styles/abstracts/variables';

.wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &.active {
    label {
      @include montserrat-12-normal;
      color: #b0b0b0;
      position: absolute;
      top: 1.6rem;
      left: 2rem;
      z-index: 2;
    }

    textarea {
      padding-top: 4rem;
    }
  }

  textarea {
    @include montserrat-12-semi-bold;
    color: #0d1444;
    position: relative;
    display: block;
    width: 100%;
    resize: none;
    background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
    border: 1px solid #dddddd;
    box-shadow: 0px 0.4rem 2rem rgba(0, 0, 0, 0.05);
    border-radius: 2rem;
    padding: 2rem 2rem 1.7rem 2rem;

    &:focus {
      outline: none;
      &::placeholder {
        color: transparent;
      }
    }

    &::placeholder {
      @include montserrat-12-normal;
      color: #b0b0b0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-transform: capitalize;
    }
  }

  .count {
    @include montserrat-12-light;
    color: #afafaf;
    display: flex;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0 2.2rem;

    span {
      @include montserrat-12-semi-bold;
    }

    p {
      margin: 0 auto;
      &:first-of-type {
        margin-right: 0.5rem;
      }
    }
  }
}
