.modal-wrapper-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  visibility: hidden;
}

.modal-wrapper-block-scroll {
  overflow: hidden !important;
}

.modal-wrapper-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.4);
  z-index: 1;
}

.modal-wrapper-card-wrapper {
  position: relative;
  padding: 20px;
  margin-top: -25px;
  transition: all ease-in-out 0.3s;
  z-index: 5;
  max-width: 500px;
  width: 100%;
  max-height: 100%;
  display: flex;
}

.modal-wrapper-card {
  background: #ffffff;
  box-shadow: 0px 20px 40px rgba(17, 29, 41, 0.6);
  border-radius: 20px;
  width: 100%;
  overflow: hidden;
}

.modal-wrapper-overlay-open {
  visibility: visible;
  opacity: 1;
  .modal-wrapper-card-wrapper {
    margin-top: 0;
  }
}

.modal-wrapper-close-button {
  width: 23px;
  height: 23px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 6;
  border-radius: 100%;
}
