@import '../../../../assets/styles/abstracts/variables';

.paymentMethod {
  &__actionCard {
    position: relative;
    border: 2px solid transparent;
    transition: 0.2s all;

    &__account {
      color: #0d1444;
    }

    .actionCard__body__subtext {
      line-height: 150%;
    }

    .actionCard__icon {
      height: 8rem;
      width: 8rem;
      margin-right: 2rem;

      img {
        height: 4rem;
        width: 4rem;
      }
    }

    &--selected {
      border: 2px solid #2f98fe;

      &::after {
        content: '✔';
        @include flex-center;
        position: absolute;
        top: -0.8rem;
        right: -0.8rem;
        width: 2rem;
        height: 2rem;
        border: 4px solid #fff;
        background: #2894ffed;
        color: #fff;
        font-weight: 600;
        border-radius: 50%;
      }
    }
  }

  &__info {
    border-top: 1px solid #d9d9d9;
    margin-top: 2rem;
    padding-top: 2rem;
  }

  &__fixedbottombtn {
    display: flex;
    justify-content: center;
    width: 20rem;
    left: calc(50% - 10rem);
    img {
      display: none;
    }
  }
}

.bankinfo {
  &__navigation {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    &__dot {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background: #dfdfdf;
      margin: 0 0.5rem;

      &--active {
        background: #262a33;
      }
      &--filled {
        background: #3ea2fc;
      }
    }
  }

  &__bottomBtn {
    margin-top: 5.4rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__fixedbottombtn {
    position: fixed;
    bottom: 9rem;
    left: calc(50% - 10rem);
    // display: flex;
    // justify-content: center;
    // width: 20rem;
    // left: calc(50% - 10rem);
    // &--blue {
    //   background: $blue92deg;
    //   height: 5rem;
    //   img {
    //     margin-right: 0;
    //   }
    // }

    // &--black {
    //   img {
    //     display: none;
    //   }
    // }
  }

  .formikInput__field.formikInput__field--filled {
    text-indent: initial;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    text-overflow: ellipsis;
  }
}
