@import '../../assets/styles/abstracts/variables';

.menu {
  .settingscard {
    margin-bottom: 1rem;
  }

  &__content__wrapper {
    .whs__wrapper {
      padding-bottom: 5rem;
    }
  }

  &__break {
    margin: 2rem 0;
    color: #d9d9d9;
    opacity: 0.5;
  }

  &__header {
    .whs__header {
      margin-bottom: 3rem;
    }
  }

  &__profile {
    position: relative;
    text-align: center;

    &__iic {
      position: relative;
      top: 0;
      left: calc(50% - 6.5rem);

      height: 13rem;
      width: 13rem;

      .iic--profile__container {
        height: 11rem;
        width: 11rem;
      }

      .iic__loader {
        height: 12rem;
        width: 12rem;
      }
    }

    &__name {
      @include montserrat-18-bold;
      margin-bottom: 0.9rem;
      color: #0d1444;
    }

    &__name__model {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;

      &__chevron__container {
        position: relative;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          &:last-of-type {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: all ease-in-out 0.2s;
          }
        }
        &__open {
          img {
            &:last-of-type {
              opacity: 1;
            }
          }
        }
      }
    }

    &__username {
      @include montserrat-14-normal;
      margin: 0;

      &--followers {
        color: #2f98fe;
        margin-right: 1rem;
      }

      &--name {
        color: #0d1444;
      }
    }

    &__stats {
      @include montserrat-14-normal;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      &__num {
        color: #828c94;
        padding: 0 1rem;
        border-right: 1px solid #d9d9d9;
        &:not(:first-of-type):last-of-type {
          border-right: 0;
        }
      }

      &__username {
        margin-left: 1rem;
        color: #0d1444;
      }

      span {
        color: #2f98fe;
      }
    }
    &__stats__model {
      @include montserrat-14-normal;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
      box-shadow: 0px 20px 40px rgba(17, 29, 41, 0.6);
      border-radius: 20px;
      padding: 18px 15px;
      z-index: 1;
      transition: all 0.2s;
      &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
      &__account {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }
      }
      &__img__container {
        min-width: 60px;
        min-height: 60px;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
      &__all__stats__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
      }
      &__name {
        font-size: 14px;
        line-height: 17px;
        font-weight: 600;
        margin: 0;
        margin: 1px 0;
      }
      &__username {
        margin: 0;
        margin: 1px 0;
        font-size: 12px;
      }
      &__num__container {
        display: flex;
        align-items: center;
        margin: 1px 0;
        .menu__profile__stats__num {
          margin: 0;
          font-size: 12px;
          &:first-of-type {
            padding-left: 0;
          }
        }
      }
    }
    &__stats__model_open {
      margin-top: 14px;
      opacity: 1;
      visibility: visible;
    }
  }
}

.menu-option-subtxt {
  @include montserrat-normal;
  font-weight: 500;
  font-size: 12px;
  color: #778797;
}
