.users_container {
  max-height: 380px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
}

.user_card {
  & :global(.actionCard--avatar) {
    width: 40px;
    height: 40px;
  }
  & :global(.actionCard__body__subtext) {
    color: #828c94;
    font-size: 12px;
  }
}

.user_avatar {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: cover;
  }
}

.user_claimed {
  color: #afafaf;
  span {
    color: rgb(13, 20, 68);
    font-size: 12px;
  }
}

.user_chat_link {
  background: #ffffff;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close_container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f2f6f9;
  border-radius: 40px;
}

.button_close {
  background: #f5f5f6;
  border-radius: 20px;
  border: none;
  font-family: 'Montserrat', sans-serif;
  color: #757576;
  font-size: 14px;
  width: 100px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
