@import '../../../assets/styles/abstracts/variables';

.container {
  align-items: center;
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  border-radius: 2rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
}

.leftContainer {
  align-items: center;
  display: flex;
  .stripe {
    border-radius: 2.5rem;
    box-shadow: 0px 10px 10px rgba(78, 172, 249, 0.2);
    height: 30px;
    margin-left: 1rem;
    width: 3px;
  }
  .itemImage {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2rem;
    height: 60px;
    margin-left: 0.7rem;
    width: 60px;
  }
}

.rightContainer {
  margin-right: 2rem;
  .optionsButton {
    align-items: center;
    background: rgba($color: #f1f1f1, $alpha: 0.5);
    border: none;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
    .dot {
      background: #767676;
      border-radius: 50%;
      height: 0.3rem;
      margin-bottom: 0.3rem;
      width: 0.3rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  .itemType {
    @include montserrat-14-normal;
    color: #0d1444;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .postingTime {
    @include montserrat-12-normal;
    color: #0d1444;
    margin: 0;
    margin-bottom: 0.2rem;
    padding: 0;
  }
  .group {
    @include montserrat-12-normal;
    color: #828c94;
    span {
      background: linear-gradient(140.46deg, #b8deff 14.38%, #8bc5ff 94.98%);
      border-radius: 3.5rem;
      color: #ffffff;
      margin-left: 0.5rem;
      padding: 0.6rem 0.7rem;
    }
  }
}
