@import '../../../assets/styles/abstracts/variables';

.ssAccordion {
  @include montserrat-14-bold;
  background: $white89deg;
  color: #0d1444;
  border-radius: 20px;
  transition: 0.2s height;
  overflow: hidden;
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border: 1px solid transparent;

    &--expanded {
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &__body {
    padding-top: 1rem;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
  }
}
