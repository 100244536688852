@import '../../../assets/styles/abstracts/variables';

.whitebutton {
  @include montserrat-14-bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f6;
  border-radius: 160px;
  padding: 0 2rem;
  height: 3.6rem;

  text-align: center;
  letter-spacing: 0.02em;

  color: #757576;
}
