.conversation_page_wrapper {
  height: 100vh;
  overflow: hidden;
  & :global(.whs__wrapper) {
    overflow: hidden;
    height: 100%;
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
  }
  & :global(.whs) {
    overflow: hidden;
    height: 100%;
    display: flex;
  }
  & :global(.content__wrapper) {
    overflow: hidden;
    height: 100%;
    display: flex;
  }
}

.conversation_loader_container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.conversation_title {
  display: flex;
  align-items: center;
}

.other_user_avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 15px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }
}
