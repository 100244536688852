@import '../../assets/styles/abstracts/variables';

.fansList {
  background: $white89deg;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 2rem;
  width: 100%;
  height: 7.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
  margin-bottom: 1rem;

  &.dark {
    background: #262a33;
    padding-right: 4rem;

    .fansList__left__title {
      @include montserrat-14-semi-bold;
      color: #fff;
    }
    .fansList__left__fansNumber {
      @include montserrat-14-normal;
      color: #778797;
    }

    .groupCircles__overlay {
      @include montserrat-12-semi-bold;
      background: linear-gradient(140.46deg, #93ceff 14.38%, #2894ff 94.98%);
    }
  }

  &__left {
    &__title {
      @include montserrat-16-semi-bold;
      color: #0d1444;
      margin: 0;
      margin-bottom: 0.5rem;
      text-transform: capitalize;
    }

    &__fansNumber {
      @include sf-14-normal;
      font-weight: 400;
      color: #828c94;
      margin: 0;
    }
  }

  &__right {
    display: flex;
    flex-direction: row-reverse;

    &__avatar {
      height: 3.6rem;
      width: 3.6rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      &:nth-child(1) {
        z-index: 10;
      }
      &:nth-child(2) {
        transform: translateX(10px);
        z-index: 9;
      }

      &:nth-child(3) {
        border: 2px solid green;
        transform: translateX(20px);
        z-index: 8;
      }

      &:nth-child(4) {
        transform: translateX(30px);
        z-index: 7;
      }

      &:nth-child(5) {
        transform: translateX(40px);
        z-index: 6;
      }
    }
  }
}

.fansNumber-overlay {
  @include flex-center;
  @include montserrat-12-bold;
  position: absolute;
  width: 3.6rem;
  height: 3.6rem;
  z-index: 20;
  background: $black0deg;
  border-radius: 3.5rem;
  color: #ffffff;
}
