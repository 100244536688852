@import '../../assets/styles/abstracts/variables';

.ll {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $white180deg;

  &__cover {
    position: absolute;
    width: 100%;
    height: 50%;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
  }

  &__body {
    background: $whiteAuthCard;
    width: 100%;
    border-radius: 4rem 4rem 0 0;
    display: flex;
    justify-content: center;
    box-shadow:
      0 0 11vh rgba(0, 0, 0, 0.5),
      inset 0 0 9vh rgba(186, 186, 186, 0.75);
    padding-top: 5rem;
    min-height: 60vh;
    max-height: calc(100vh - 15rem);
    position: absolute;
    bottom: 0;
    overflow-y: visible;
    &__children {
      width: 100%;
      max-height: calc(100% - 7rem);
      overflow-y: auto;
      max-width: 60rem;
    }

    &--form {
      // height: 64rem;
      height: 70%;
    }
  }

  &__circle-container {
    position: relative;
  }

  &__logos {
    &__circle {
      height: 12rem;
      width: 12rem;

      .iic--login__container img {
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
        object-fit: scale-down;
        overflow: hidden;
      }

      &--form {
        top: calc(30% - 6rem);
      }
    }
    &__svg1 {
      &::before {
        content: '';
        width: 3.4rem;
        height: 3.4rem;
        background: #0fecd9;
        display: block;
        border-radius: 50% 50% 0 50%;
        position: absolute;
        opacity: 0.7;
        left: 40%;
        top: 25%;
      }

      &::after {
        content: '';
        width: 3.4rem;
        height: 3.4rem;
        background: #4964de;
        display: block;
        border-radius: 50% 50% 0 50%;
        transform: rotate(180deg);
        position: absolute;
        opacity: 0.7;
        right: 40%;
        top: 40%;
      }
    }
  }
}

.dark-theme {
  .ll {
    background: $black157deg;

    &__cover {
      background: $black157deg;
    }

    &__body {
      background: $black157deg;
    }

    &__right {
      background: $black157deg;
    }
  }
}
