.add-fans-group-modal-container {
  display: flex;
  flex-direction: column;
}

.add-fans-group-modal-button {
  margin-left: 10px;
  cursor: pointer;
  transition: opacity ease-in-out 0.3s;
  user-select: none;
  &-loading {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
  }
}

.add-fans-group-modal-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
}
