@import '../../../../assets/styles/abstracts/variables';

.separator {
  border-top: 1px solid #d9d9d9;
  margin-bottom: 2.5rem;
}

.minMaxContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 3rem;

  .minMax {
    @include montserrat-12-light;
    strong {
      font-weight: 600;
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2.5rem;
}
