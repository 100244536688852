.container {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  * {
    font-family: inherit;
  }
}

.share_item {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #d9d9d9;
  font-weight: 600;
  font-size: 14px;
  color: #0d1444;
  svg {
    margin-right: 13px;
  }
  &:first-of-type {
    padding-top: 0;
  }
}

.share_story {
  color: #afafaf;
  border-bottom: none;
}
