@import '../../../assets/styles/abstracts/variables';

.progressBar {
  height: 0.5rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;

  &__range {
    width: 0;
    height: 100%;
    background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
    border-radius: 5px;
    transition: all 0.2s ease;
  }
}
