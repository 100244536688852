@import '../../../assets/styles/abstracts/variables';

.photoUploadCard {
  display: flex;

  &:not(:last-of-type) {
    margin-bottom: 1.8rem;
  }

  &__icon {
    position: relative;

    span {
      @include montserrat-10-semi-bold;
      text-transform: uppercase;
      display: block;
      position: absolute;
      top: 2.3rem;
      left: 50%;
      transform: translateX(-50%);
      color: #26b99a;
      line-height: 150%;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 1.2rem 0 2rem;

    &--top {
      @include montserrat-12-semi-bold;
      display: flex;
      justify-content: space-between;
      line-height: 15px;

      .photoName {
        color: #0d1444;
        word-break: break-word;
      }
      .photoPercent {
        color: #b0b0b0;
      }
      @media (max-width: 365px) {
        flex-wrap: wrap;
      }
    }

    &--bottom {
      @include montserrat-12-semi-bold;
      color: #b0b0b0;
    }

    &--progress {
      padding: 0.6rem 0 0.4rem 0;
      progress[value] {
        display: block;
        width: 100%;
      }
    }
  }

  &__action {
    display: flex;
    align-items: center;

    .circleWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.8rem;
      height: 1.8rem;
      border-radius: 50%;
      background: linear-gradient(180deg, #f5f5f5 12.4%, #f8f8f8 99.26%);
    }
  }
}
