@import '../../assets/styles/abstracts/variables';

.footer {
  background: $black157deg;
  height: 7rem;
  position: fixed;
  bottom: -1px;
  width: 100%;
  border-radius: 3.5rem 3.5rem 0 0;
  z-index: 10;

  display: flex;
  justify-content: space-around;
  align-items: center;

  &__link {
    -webkit-tap-highlight-color: transparent;
    min-width: 2.5rem;
    position: relative;

    &__status {
      @include montserrat-bold-700;
      @include flex-center;
      position: absolute;
      top: -1rem;
      right: -1rem;
      min-width: 2rem;
      height: 2rem;
      color: #fff;
      padding: 0 0.3rem;
      background: $blue92deg;
      border-radius: 160px;
    }

    img {
      height: 2.3rem;
    }

    &--addpost {
      @include flex-center;
      background: #fff;
      height: 4.2rem;
      width: 4.2rem;
      border-radius: 50%;

      box-shadow:
        0 0 0 4px #131319,
        0 0 0 6px rgba(186, 186, 186, 0.75);

      img {
        height: 1.6rem;
        transform: rotate(0);
        transition: 0.2s transform;
      }

      &--active {
        background: $blue92deg;
        border: 3px solid #76bcff;
        img {
          transform: rotate(45deg);
          filter: brightness(100);
        }
      }
    }

    &--avatar {
      border-radius: 50%;
      height: 2.6rem !important;
      width: 2.6rem;
      object-fit: cover;
    }
  }

  &__posts {
    display: flex;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(0);
    height: 6rem;
    max-width: calc(100% - 4.5rem);
    border-radius: 3.5rem 0 0 3.5rem;
    background: $black157deg;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    transition: 0.2s all;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    &--active {
      opacity: 1;
      transform: translateY(-9rem);
      pointer-events: all;
    }

    :last-child {
      margin-right: 2.5rem;
    }

    &__link {
      @include flex-center;
      @include montserrat-12-bold;
      flex: 1;
      text-decoration: none;
      color: #757576;
      -webkit-tap-highlight-color: transparent;
      margin-left: 2.5rem;

      img {
        filter: brightness(100);
        margin-right: 1.2rem;
      }
      svg {
        margin-right: 1.2rem;
      }

      &--active {
        color: #ffffff;
      }
    }
  }
}
