@import '../../../../assets/styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  backdrop-filter: blur(19px) brightness(0.8);
}

.goLeft,
.goRight {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.goRight {
  left: 50%;
}

.background {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.actionsContainer {
  width: 5.6rem;
  position: absolute;
  right: 1.8rem;
  top: 12rem;
  z-index: 7;
}

.pollContainer {
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  bottom: 10rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  :global(.yesNoQuestion__input) {
    @include montserrat-22-semi-bold;
    color: #fff;
    text-align: center;
  }
  :global(.poll__option__percentage) {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(106, 128, 108, 0.2), rgba(106, 128, 108, 0.2)),
      linear-gradient(0deg, #ffffff, #ffffff), #f2f6f9;
  }
  :global(.poll__option__input) {
    @include montserrat-14-semi-bold;
    color: #0d1444;
    width: 100%;
    background-color: transparent;
    border: none;
    box-shadow: none;
    position: relative;
    flex: 1;
  }
  :global(.poll__option__icon) {
    position: relative;
  }
  :global(.poll__option__count) {
    position: relative;
    @include montserrat-18-bold;
  }
  :global(.poll__header) {
    @include montserrat-18-semi-bold;
    color: #ffffff;
    text-align: center;

    p {
      position: relative;
      margin: 0;
    }
  }
  :global(.yesNoQuestion__button) {
    position: relative;
    min-width: 5rem;
    overflow: hidden;
  }
  :global(.askQuestion__header) {
    @include montserrat-22-semi-bold;
    color: #fff;
    text-align: center;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    p {
      margin: 0;
      position: relative;
    }
  }
  :global(.askQuestion__content) {
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    margin-bottom: 2rem;
    overflow: hidden;
  }
  :global(.askQuestion__footer) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  :global(.yesNoQuestion__button__text) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  :global(.yesNoQuestion__button__text--count) {
    @include montserrat-18-bold;
    font-weight: 800;
    color: #0d1444;
  }
  :global(.yesNoQuestion__button--percentage) {
    background: linear-gradient(0deg, rgba(106, 128, 108, 0.2), rgba(106, 128, 108, 0.2)),
      linear-gradient(0deg, #ffffff, #ffffff), #f2f6f9;
  }
  .pollPreview {
    background-color: red;
  }
  .pollPreview__wrapper {
    padding: 0rem;
  }
}

.textContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
