.arrows__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    img,
    svg {
      transform: rotate(180deg);
    }
  }
}
.arrow__flashing {
  display: block;
  &:not(:last-of-type) {
    margin-right: 0.4rem;
  }
  &.first {
    animation: arrowFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &.second {
    animation: arrowFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  &.third {
    animation: arrowFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes arrowFlashing {
  0% {
    opacity: 1;
  }
  // 50% {
  //   opacity: 0.7;
  // }
  100% {
    opacity: 0.3;
  }
}
