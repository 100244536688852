.container {
  align-items: auto;
  align-content: start;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}

:global {
  .stream-archive-whs {
    .whs__header {
      border-bottom: 1px solid #d9d9d9 !important;
    }
  }
}
