@import '../../../assets/styles/abstracts/variables';

.react-calendar {
  @include montserrat-16-normal;
  border: none;

  &__navigation {
    height: auto;

    button {
      border-radius: 10px;
      height: 30px;
      line-height: 20px;
      min-width: 30px;
      padding: 0 6px;
      width: 30px;
      &:hover {
        background-color: #f1f1f1 !important;
        opacity: 0.7;
      }
    }

    &__prev-button,
    &__next-button {
      align-items: center;
      background-color: #f1f1f1 !important;
      display: flex;
      font-size: 20px;
      font-weight: 500;
      justify-content: center;
      opacity: 0.5;
    }

    &__prev2-button,
    &__next2-button {
      display: none;
    }

    &__label {
      @include montserrat-16-normal;
      color: #0d1444;
    }
  }

  &__tile {
    background: transparent;
    padding: 5px 3px;
    position: relative;

    color: rgba($color: #000000, $alpha: 1);

    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: rgba(16, 16, 16, 0.3);
      &.react-calendar__month-view__days__day--weekend {
        color: rgba($color: #000000, $alpha: 0.3);
      }
    }
    abbr {
      align-items: center;
      border-radius: 50%;
      display: flex;
      font-size: 14px;
      height: 30px;
      justify-content: center;
      margin: auto;
      padding: 0;
      width: 30px;
    }

    &--now {
      background: transparent;
      &:enabled:hover {
        background: transparent;
      }
      abbr {
        border: 1px solid #0d1444;
      }
    }

    &--active {
      background: transparent;
      abbr {
        background: $blue92deg;
        border: 1px solid transparent;
        box-shadow: $shadow-blue;
        color: #ffffff;
      }
      &:enabled,
      &:focus,
      &:enabled:focus {
        background: #ffffff;
      }
    }
  }

  &__month-view {
    &__weekdays {
      color: #0d1444;
      font-weight: 600 !important;
      font-size: 12px;
      line-height: 15px;

      abbr {
        text-decoration: none;
      }
    }
    &__days__day--weekend {
      color: #000000;
    }
    &__days__day {
      .dots-container {
        display: flex;
        flex-wrap: wrap;
        height: 8px;
        margin: auto;
        margin-top: 5px;
        width: 24px;
        justify-content: center;
        .story {
          min-width: 5px;
        }
        .dot {
          border-radius: 50%;
          flex-shrink: 0;
          height: 5px;
          margin-right: 3px;
          width: 5px;

          &:nth-child(-n + 3) {
            margin-bottom: 3px;
          }
          &:nth-child(4) {
            margin-left: 3px;
          }
          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          &.post {
            background-color: #6abeff;
          }
          &.premium {
            background-color: #f7de5b;
          }
          &.message {
            background-color: #d27fbb;
          }
          &.story {
            background-color: #7fd287;
          }
          &.stream {
            background-color: #ff6f6f;
          }
        }
      }
    }
  }
}
