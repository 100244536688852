@import '../../assets/styles/abstracts/variables';
.processingFile {
  position: relative;
  width: 11.5rem;
  height: 11.5rem;
  border: 3px solid transparent;

  &__video {
    width: 17.5rem;
  }

  // &:not(:last-of-type) {
  //   margin-right: 1rem;
  // }

  &__abort {
    position: absolute;
    z-index: 2;
    top: -0.5rem;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    border-radius: 2rem;
    background: linear-gradient(140.46deg, #6b7e8d 14.38%, #2894ff 94.98%);
    opacity: 0.1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-bottom: 1.8rem;
  }

  &__loader {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 2rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    &__backdrop {
      position: absolute;
      z-index: 12;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #dbdbdb, $alpha: 0.5);
    }
  }

  &__progress {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 1rem 1rem 1rem;

    &--bar {
      // padding: 0.6rem 0 0.4rem 0;
      width: 100%;
      progress[value] {
        display: block;
        width: 100%;
      }
    }
  }

  &__text {
    @include montserrat-12-light;
    color: #a9b7c5;
    line-height: 1.8rem;
  }
}

.processingFileLoader {
  display: block;
  color: #000;
  font-size: 4px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow:
      0 -3em 0 0.2em,
      2em -2em 0 0em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 0;
  }
  12.5% {
    box-shadow:
      0 -3em 0 0,
      2em -2em 0 0.2em,
      3em 0 0 0,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  25% {
    box-shadow:
      0 -3em 0 -0.5em,
      2em -2em 0 0,
      3em 0 0 0.2em,
      2em 2em 0 0,
      0 3em 0 -1em,
      -2em 2em 0 -1em,
      -3em 0 0 -1em,
      -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 0,
      2em 2em 0 0.2em,
      0 3em 0 0em,
      -2em 2em 0 -1em,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  50% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 0em,
      0 3em 0 0.2em,
      -2em 2em 0 0,
      -3em 0em 0 -1em,
      -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow:
      0 -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 0,
      -2em 2em 0 0.2em,
      -3em 0 0 0,
      -2em -2em 0 -1em;
  }
  75% {
    box-shadow:
      0em -3em 0 -1em,
      2em -2em 0 -1em,
      3em 0em 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0.2em,
      -2em -2em 0 0;
  }
  87.5% {
    box-shadow:
      0em -3em 0 0,
      2em -2em 0 -1em,
      3em 0 0 -1em,
      2em 2em 0 -1em,
      0 3em 0 -1em,
      -2em 2em 0 0,
      -3em 0em 0 0,
      -2em -2em 0 0.2em;
  }
}
