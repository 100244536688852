@import '../../../../assets/styles/abstracts/variables';

.writeMessage {
  position: fixed;
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 2rem;
  z-index: 3;
  bottom: 0;
  left: 0;
  border-radius: 2.5rem 2.5rem 0 0;
  background: $white90deg;
  transition: 0.2s height;

  &--small {
    height: 9rem;

    .directmessage__attachments {
      height: 30rem;
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &--recording {
    z-index: 99;
  }

  &__reply {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    width: 90%;
    height: 3rem;
    background: #808080ad;
    color: #fff;
    border-radius: 1rem 1rem 0.5rem 0.5rem;
    padding: 0.25rem 0.5rem;
    bottom: 11rem;
  }

  &__tags {
    position: absolute;
    bottom: 12rem;
    padding: 2rem;
    width: 80%;
    height: 0;
    overflow-y: hidden;
    background: #fffcfc;
    box-sizing: border-box;
    border-radius: 1.5rem;
    transition: 0.4s height;

    &--active {
      height: 24rem;
      overflow-y: auto;
      border: 1px solid rgba(38, 42, 51, 0.2);
    }

    &__tag {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
    }

    &__info {
      margin-left: 1rem;
      p {
        margin: 0;
      }
      &--name {
        @include montserrat-14-bold;
        color: #0d1444;
      }
      &--username {
        @include montserrat-12-normal;
        color: #828c94;
      }
    }
  }

  &__top {
    display: flex;
    align-items: flex-end;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__field {
    display: flex;
    align-items: center;
    width: 80%;
    position: relative;
    flex: 1;
    &--plus {
      padding-left: 2rem;
      margin-right: 2rem;
    }

    & textarea {
      @include montserrat-14-bold;
      background: #fffcfc;
      border: 1px solid rgba(38, 42, 51, 0.2);
      border-radius: 1.5rem;
      box-sizing: border-box;
      font-weight: 500;
      outline: none;
      width: 100%;
      resize: none;
      -ms-overflow-style: none;
      padding-right: 6.5rem;
      padding-top: 1.4rem;
      padding-left: 1.6rem;
      height: 5rem;
      padding-bottom: 1.4rem;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--smile {
      position: absolute;
      right: 2rem;
      bottom: 1.4rem;
      &--gif {
        right: 4.2rem;
      }
    }

    &--gif {
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 1rem;
      bottom: 1.4rem;
    }

    &__audio {
      padding: 1rem;
    }
  }

  &__sendBtn {
    background: $blue92deg;
    border: 0.3rem solid #76bcff;
    box-sizing: border-box;
    box-shadow: 0px 0.4rem 1.4rem rgba(47, 152, 254, 0.5);
    border-radius: 16rem;
    width: 5rem;
    height: 5rem;
    position: relative;
    margin-left: 1rem;

    & img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
    }

    &--sending {
      pointer-events: none;
      opacity: 0.7;
    }
    &--disabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: default;
    }
  }

  &__bot {
    display: flex;
    padding-top: 0.5rem;
    &__btn {
      height: 3rem;
      width: 3rem;
      margin-right: 1rem;

      &--audiopause {
        img {
          height: 1.25rem;
          filter: brightness(0.5);
        }
      }
    }
  }
}

.emojiPicker {
  position: absolute;
  bottom: 110%;
  right: 0;
}

@media (max-width: 430px) {
  .emojiPicker {
    right: -7rem;
  }
}

@keyframes flyAway {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(0%, -100%) scale(0);
  }
}

.write-message-audio-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 2rem;
  z-index: 5;
  background-color: #ffffff;
  border-radius: 2rem;
}

.write-message-audio-preview-wrapper {
  max-width: 93%;
}

.write-message-audio-preview-remove {
  flex-shrink: 0;
  margin-right: 1rem;
  border-radius: 100%;
  background-color: transparent;
  width: 2rem;
  height: 2rem;
  border: 1px solid #778797;
  display: flex;
  align-items: center;
  justify-content: center;
}

.write-message-schedule-message {
  margin-left: auto;
  .write-message-schedule-message-bubble {
    margin-bottom: 0;
    background-color: transparent;
    display: block;
  }
}
