@import '../../../assets/styles/abstracts/variables';

.userCard {
  display: flex;
  width: 100%;

  background: $white89deg;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 1rem;

  &__transactions {
    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem;

      &--left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      &--right {
        flex-shrink: 1;
        min-width: 0;
        display: flex;
      }
    }
  }

  &--friend {
    height: 10rem;
    padding: 1rem;
  }
  &--friendReq {
    height: 11rem;
  }
  &--blocked {
    height: 8rem;
    align-items: center;
    padding: 0 1rem;
  }
  &--earnings {
    align-items: center;
    flex-direction: column;
    height: auto;
    &--content {
      display: flex;
      align-items: center;
      padding: 1rem 1.6rem 1rem;
      width: 100%;
    }
  }

  &--fan {
    padding: 1rem;
  }

  &__avatar {
    margin-right: 1.5rem;
    @media (max-width: 360px) {
      margin-right: 1rem;
    }
  }

  &__values {
    text-align: end;
    flex-shrink: 1;
    min-width: 0;

    &__amount {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0.4rem;

      &--price {
        @include montserrat-16-semi-bold;
        margin: 0;
        color: #0d1444;
        margin-left: 1rem;
      }
      &--error {
        @include montserrat-12-normal;
        margin: 0;
        color: #ff4b55;
        margin-bottom: 0.7rem;
      }
    }

    &__date {
      @include montserrat-12-normal;
      margin: 0;
      color: #b0b0b0;
      margin-bottom: 0.6rem;
      text-overflow: ellipsis;
      overflow-x: hidden;
      white-space: nowrap;
    }
    &__order {
      @include montserrat-12-normal;
      margin: 0;
      color: #b0b0b0;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
    min-width: 70px;
    margin-right: 0.5rem;

    &__name {
      @include montserrat-14-semi-bold;
      @include text-ellipsis;
      color: #0d1444;
      margin: 0 0 0.5rem 0;
      line-height: 17px;

      span {
        font-weight: 400;
      }
    }

    &__username {
      @include montserrat-12-normal;
      @include text-ellipsis;
      font-weight: 500;
      color: #828c94;
      margin: 0 0 0.5rem 0;
    }
    &__type {
      @include montserrat-11-normal;
      font-weight: 500;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin: 0;
      width: fit-content;

      &__subscription {
        color: #3199fe;
        border: 1px solid #b4daff;
      }
      &__tip {
        color: #10cbbb;
        border: 1px solid #a1e7e1;
      }
      &__deposit {
        color: #16cb10;
        border: 1px solid #a8e7a1;
      }
      &__message {
        color: #cb8610;
        border: 1px solid #e7bca1;
      }
      &__post {
        color: #7410cb;
        border: 1px solid #a1a6e7;
      }
    }

    &__fans {
      @include montserrat-12-bold;
      @include text-ellipsis;
      font-weight: 500;
      color: #828c94;
      margin: 0 0 0.5rem 0;

      span {
        font-weight: 600;
        color: #2894ff;
      }
    }

    &__time {
      @include sf-12-normal;
      @include text-ellipsis;
      margin: 0 0 0.5rem 0;
      color: #b0b0b0;
    }

    &__message {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 800;
      letter-spacing: 0.02em;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #349bfd;
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    .iconbutton {
      border: 1px solid #f4f4f4;
    }

    .iconbutton:first-of-type {
      margin-right: 1rem;
    }

    &__acceptfr {
      width: 6rem !important;
    }

    &__deny {
      height: 4rem !important;
      width: 4rem !important;
      border-radius: 50% !important;
      margin-left: 0.7rem;
    }

    &__accept {
      font-weight: 500 !important;
      font-size: 1.4rem !important;
      height: 4rem !important;
    }
  }

  &__stats {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__expire {
      @include sf-12-normal;
      color: #b0b0b0;
      margin: 0;
      margin-bottom: 0.5rem;

      span {
        font-weight: 600;
        color: #e40606;
      }
    }
    &__renews {
      @include sf-12-normal;
      color: #b0b0b0;
      margin: 0;
      margin-bottom: 0.5rem;

      span {
        font-weight: 600;
        color: #2f98fe;
      }
    }

    &__money {
      @include sf-12-normal;
      color: #b0b0b0;
      margin: 0;

      span {
        color: #000;
      }
    }
  }
  .earnings {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 2;

    &--money {
      @include montserrat-16-bold;
      margin: 0;
      margin-bottom: 0.7rem;
    }
    &--date {
      font-family: $sf-pro;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 150%;
      color: #b0b0b0;
      margin: 0;
      text-align: end;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;

    &__button {
      margin-left: 0.5rem;
      border: 1px solid #f4f4f4;
    }
  }

  &__fan {
    &--modal {
      top: -1rem;

      .modal__card {
        overflow: hidden;
      }
    }
  }
}

.earnings-dropdown {
  max-height: 0;
  transition: all ease-in-out 0.3s;
  overflow: hidden;
  width: 100%;
  &-open {
    max-height: 100vh;
  }
}

.earnings-dropdown-button {
  background: #f6f6f6;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin-left: 20px;
  &-open {
    .earnings-dropdown-button-icon {
      transform: rotate(180deg);
    }
  }
}

.earnings-dropdown-button-icon {
  transition: transform ease-in-out 0.3s;
}

.typeBadge {
  @include montserrat-11-normal;
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  max-width: fit-content;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--blue {
    border: 1px solid #b4daff;
    color: #3199fe;
  }
  &--green {
    border: 1px solid #a1e7e1;
    color: #10cbbb;
  }
  &--yellow {
    border: 1px solid #f7df6380;
    color: #f7df63;
  }
  &--pink {
    border: 1px solid #d27eba80;
    color: #d27eba;
  }
}
