.container {
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  * {
    font-family: inherit;
  }
}

.top_container {
  display: flex;
}

.media_container {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

.media_wrapper {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.price_container {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 5px;
  padding-top: 3px;
  color: #ffffff;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
}

.price_background {
  background: linear-gradient(156.63deg, #181920 9.6%, #0f0f13 108.23%);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 6px;
  z-index: -1;
  opacity: 0.3;
}

.media_count_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
}

.media_count {
  display: flex;
  align-items: center;
  color: #828c94;
  font-size: 12px;
  svg {
    margin-right: 4px;
  }
}

.stats_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1px 2px;
}

.stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  color: #828c94;
}

.stat_value {
  color: #0d1444;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.stat_value_none {
  color: #778797;
}

.stat_value_count_container {
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #d9d9d9;
}

.stat_value_count {
  background-color: #e7e7e7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2894ff;
  font-size: 14px;
  padding: 1px;
  min-width: 30px;
}

.text_container {
  margin: 0 20px;
  padding-bottom: 17px;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #778797;
  border-bottom: 1px solid #d9d9d9;
}

.bottom_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  margin-bottom: 8px;
}

.bottom_date {
  color: #afafaf;
  font-size: 12px;
  span {
    color: #0d1444;
  }
}

.unsend_btn {
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: #2894ff;
  font-size: 12px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
}
