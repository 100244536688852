@import '../../assets/styles/abstracts/variables';
@media (max-width: 680px) {
  .logo-container {
    display: none;
  }
}

.header {
  height: 6rem;
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #070707;
  color: #fff;

  .header--content {
    display: flex;
    justify-content: center;
  }

  &--withnav {
    min-height: 12rem;
  }

  &__navigation {
    padding: 0.5rem;
    position: absolute;
    bottom: 2rem;
    height: 4rem;
    background-color: #262a33;
    width: calc(100% - 8rem);
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;

    &__link {
      @include flex-center;
      @include montserrat-12-normal;
      text-decoration: none;
      flex: 1;
      height: 100%;

      &:focus {
        color: yellow;
      }

      &--inactive {
        color: #757576;
      }
      &--active {
        background: #fff;
        box-shadow: 0 0.4rem 1.4rem rgba(0, 0, 0, 0.15);
        border-radius: 2rem;
        color: #0d1444;
      }
    }
  }

  &__title {
    @include montserrat-22-semi-bold;
    margin: 1.6rem 0 0 0;
  }

  &__right {
    position: absolute;
    right: 0;
    height: 100%;
  }

  &__linkback {
    position: absolute;
    font-size: 2.2rem;
    left: 2rem;
    top: 2rem;
    height: 1.8rem;
    width: 0.6rem;

    &::before {
      content: '';
      width: 0.2rem;
      height: 62%;
      position: absolute;
      background-color: #fff;
      transform: rotate(45deg);
      left: 0;
      top: 0;
    }
    &::after {
      content: '';
      width: 0.2rem;
      height: 62%;
      position: absolute;
      background-color: #fff;
      transform: rotate(-45deg);
      left: 0;
      bottom: 0;
    }
  }
}

@media (min-width: 680px) {
  .header {
    background-color: white;
    align-items: center;
    padding-left: 5rem;
    .header--content {
      width: 100%;
      max-width: 68rem;
    }
    &__title {
      color: black;
      font-family: Montserrat;
      font-size: 1.4rem;
      line-height: 1.7rem;
      letter-spacing: 0.02em;
      text-align: left;
      margin-top: 0;
      cursor: default;
    }
    &__linkback {
      filter: invert(1);
      cursor: pointer;
    }
    &--withnav {
      min-height: unset;
      .header__title {
        display: none;
      }
      .navbar__header {
        position: static;
      }
    }
  }
  .logo-container {
    height: 6rem;
    display: flex;
    align-items: center;
    min-width: 22rem;
    padding-left: 4rem;
    margin-right: 8rem;
  }
  .logo-container > a {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  .logo-container:hover {
    background-color: unset;
  }
  .logo-img {
    width: 3.6rem;
    height: 4.4rem;
  }
  .logo-text {
    font-family: Montserrat;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.2rem;
    letter-spacing: 0.02em;
    text-align: left;
    color: black;
  }
}
