.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  // padding: 20px;
  z-index: 99;
  & :global(.swiper) {
    width: 100%;
  }
  & :global(.swiper-wrapper) {
    align-items: center;
  }
  & :global(.swiper-slide) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.container_overlay {
  background-color: rgba($color: #000000, $alpha: 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.close_icon {
  position: fixed;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  cursor: pointer;
}

.fullscreen_media {
  max-width: 100%;
  width: 100%;
}

.fullscreen_photo_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.fullscreen_photo {
  height: 100vh;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
}

.fullscreen_photo_background {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: 100vh;
  z-index: -2;
  filter: blur(10px);
}

.fullscreen_photo_container_landscape {
  display: flex;
  align-items: center;
  justify-content: center;
  .fullscreen_photo {
    height: auto;
    object-fit: contain;
  }
}
