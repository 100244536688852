@import '../../../assets/styles/abstracts/variables';

.payouts {
  :global {
    .avatarholder {
      width: 6rem;
      min-width: 6rem;
      height: 6rem;
    }
    .earnings--money {
      font-weight: 600;
    }
    .earnings--date {
      @include text-ellipsis-basic;
      @include montserrat-light;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 150%;
      white-space: nowrap;
      min-width: 0;
    }
    .userCard--earnings--content {
      padding: 1rem;
    }
    .userCard__info .button {
      @include montserrat-normal;
      @include text-ellipsis-basic;
      border-radius: 0.5rem;
      font-size: 1.1rem;
      padding: 1rem 0.6rem;
      min-width: 0;
      flex-shrink: 1;
      margin-right: 1rem;
    }
    .userCard__info__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      min-width: 0;
    }
    .userCard__info__column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
      min-width: 0;
    }
  }
}
