@import '../../../../assets/styles/abstracts/variables';

.profile__photo__modal {
  .modal__card {
    overflow-y: hidden;
  }
}

.profileImageModal {
  display: flex;
  justify-content: space-between;

  &__block {
    width: 45%;
  }

  &__img {
    @include flex-center;
    height: 10rem;
    background: linear-gradient(180deg, #f5f5f5 12.4%, #f8f8f8 99.26%);
    border: 0.3rem solid #ffffff;
    border-radius: 2rem;
    box-sizing: border-box;
  }

  &__text {
    @include montserrat-12-semi-bold;
    text-align: center;
    color: #757576;
  }

  &__camera {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #000;

    &__cam {
      height: 100%;
      width: 100%;
    }

    &__capture {
      position: absolute;
      bottom: 2rem;
      left: calc(50vw - 3rem);
      width: 6rem;
      height: 6rem;
      border-radius: 50%;

      &::after {
        content: '.';
        width: 7rem;
        height: 7rem;
        position: absolute;
        top: -0.7rem;
        left: -0.7rem;
        background-color: transparent;
        user-select: none;
        border-radius: 50%;
        border: 2px solid #fff;
      }
    }

    &__rotate {
      @include flex-center;
      position: absolute;
      bottom: 3rem;
      right: 6rem;
      width: 4rem;
      height: 4rem;
      background-color: #fff;
      border-radius: 20px;

      img {
        filter: brightness(0.25);
      }
    }

    &__close {
      @include flex-center;
      position: absolute;
      top: 2rem;
      right: 4rem;
      height: 3rem;
      width: 3rem;
      background-color: #fff;
      color: #fff;
      border-radius: 50%;
    }
  }

  &__crop {
    width: 100%;

    &__isProfile {
      .cropper-view-box {
        border-radius: 50%;
      }
    }

    &__editimage {
      .cropper-view-box {
        border-radius: 0;
      }
    }

    &__block {
      @include flex-center;
      width: 100%;
      height: 30rem;
      background-color: #000;
      border: 3px solid #fff;
      border-radius: 2rem;
      margin-bottom: 2rem;
      overflow: hidden;

      &--img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__actions {
      display: flex;
      justify-content: space-between;
    }

    &__action {
      position: relative !important;

      .iic--modal__container {
        img {
          filter: brightness(0);
        }
      }

      &__rotate {
        .iic--modal__container {
          img {
            opacity: 0.2;
          }
        }
      }
    }
  }

  &__disabled {
    pointer-events: none;

    .profileImageModal__crop__action {
      img {
        opacity: 0.2;
      }
    }
  }
}
