@import '../../assets/styles/abstracts/variables';

@media (min-width: 680px) {
  .errorPage--wrapper {
    position: relative;
    min-height: 80vh;
  }
}
.errorPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -3rem;
  width: 100%;
  padding: 0 4.8rem;
  text-align: center;

  &--bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
  }

  &--h1 {
    font-family: $montserrat;
    font-size: 13rem;
    line-height: 15.8rem;
    font-weight: 500;
    color: #181920;
    margin: 2rem 0;
  }

  &--h2 {
    @include montserrat-bold;
    font-size: 2.4rem;
    color: #0d1444;
    line-height: 2.9rem;
    margin: 0;
  }

  &--p {
    @include montserrat-14-normal;
    font-weight: 500;
    line-height: 140%;
    color: #778797;
    margin: 2rem 0;
    text-align: center;
  }
}
