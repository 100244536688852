.modal_container {
  display: flex;
  flex-direction: column;
  padding: 35px 20px;
  padding-bottom: 100px;
  font-family: 'Montserrat', sans-serif;
  * {
    font-family: inherit;
  }
}

.modal_title {
  font-weight: 600;
  font-size: 18px;
  color: #0d1444;
  margin-left: 10px;
  margin-bottom: 25px;
}

.input_container {
  position: relative;
  margin-bottom: 20px;
}

.search_field {
  input {
    transition: all ease-in-out 0.3s;
  }
  &_open {
    input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.search_results {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  max-height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  border: 1px solid rgba(38, 42, 51, 0.2);
  border-radius: 0px 0px 15px 15px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  &_open {
    visibility: visible;
    opacity: 1;
  }
}

.search_card {
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  cursor: pointer;
  &:hover {
    background: #f4f4f4;
  }
}

.search_status {
  background: #2894ff;
  border: 1px solid #2894ff;
  border-radius: 5px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  padding: 5px;
  font-size: 11px;
  &_friend {
    background: #ffffff;
    color: #3199fe;
    border-color: #b4daff;
  }
}

.users_container {
  display: flex;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  align-items: flex-start;
}

.selected_user_container {
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
}

.selected_user_name {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #828c94;
  text-align: center;
  max-width: 56px;
  margin-top: 5px;
}

.selected_user_delete {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  position: absolute;
  top: -3px;
  right: -3px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  z-index: 1;
  cursor: pointer;
}

.groups_conatiner {
  display: flex;
  flex-direction: column;
  max-height: 305px;
  overflow-y: auto;
  overflow-x: hidden;
}

.separator {
  background-color: #d9d9d9;
  width: 100%;
  height: 1px;
  margin: 20px 0;
}

.group_card {
  flex-shrink: 0;
  flex-grow: 0;
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.buttons_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 15px 30px 15px;
  background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
  border-radius: 40px 40px 20px 20px;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.button {
  font-weight: 500;
  width: 128px;
  margin: 5px;
}
