@import '../../../../assets/styles/abstracts/variables';
.sortModal {
  &__section {
    @include montserrat-16-normal;
    margin: 0 !important;
    text-transform: capitalize;
    color: #828c94;
    padding-bottom: 0.8rem;
  }

  &__title {
    @include montserrat-24-normal;
    display: flex;
    justify-content: space-between;
    margin: 0 !important;
    text-transform: capitalize;
    color: #0d1444;
  }

  &__navigation {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    &__dot {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background: #dfdfdf;
      margin: 0 0.5rem;

      &--active {
        background: #262a33;
      }
      &--filled {
        background: #3ea2fc;
      }
    }
  }

  &__search {
    &__withbuttons {
      display: flex;

      &__input {
        flex: 1;
      }

      .iconbutton {
        margin-left: 0.5rem;
      }
    }
  }

  &__sort {
    margin: 0;
    padding: 0 1rem;
    @media (max-width: 375px) {
      padding: 0;
    }
    &:first-of-type {
      padding-top: 1rem;
    }

    &__wrapper {
      .button {
        margin: 0 auto 2rem auto;
      }
    }

    &__2nd {
      border-top: 1px solid #d9d9d9;
      padding-top: 2.4rem;
    }

    &__3rd {
      border-top: 1px solid #d9d9d9;
      padding-top: 2.4rem;
    }

    &__item {
      @include montserrat-14-bold;
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      font-weight: 600;
      align-items: center;
      .radiobutton {
        flex-shrink: 0;
        flex-grow: 0;
      }
      &:last-of-type {
        margin-bottom: 3rem;
      }
      &__name {
        word-wrap: break-word;
      }
    }
  }

  &__actiondd {
    position: absolute;
    right: 2rem;
    top: 10rem;
    width: 24rem;
    background: linear-gradient(115.6deg, #ffffff -3.22%, #ffffff 102.21%);
    z-index: 1001;
    border: 3px solid #ffffff;
    border-radius: 20px;
    transform: scaleY(0);
    transform-origin: top;
    transition: 0.2s transform;

    &--active {
      transform: scaleY(1);
    }

    &__item {
      @include montserrat-14-bold;
      height: 4.5rem;
      display: flex;
      align-items: center;
      padding: 0 2rem;

      &:hover {
        background: #f4f4f4;
      }

      img {
        margin-right: 1rem;
      }

      &:first-of-type {
        margin-top: 1rem;
      }
      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }
}

.sortModal__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button.sortModal--btn {
  margin: 0rem 0.5rem 2rem;
}

.sort-modal-select-options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 6px;
  background-color: #f5f5f6;
  border-radius: 20px;
  position: relative;
  width: 55%;
  font-family: 'Montserrat', sans-serif;
  margin-left: auto;
  margin-right: 10px;
}

.sort-modal-select-options-nav-buttons {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-modal-select-options-selected {
  color: #828c94;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  user-select: none;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  &-active {
    color: #2894ff;
  }
}

.sort-modal-select-options-checkbox {
  display: none;
  &:checked ~ .sort-modal-select-options-all-options {
    opacity: 1;
    visibility: visible;
  }

  &:checked ~ .sort-modal-select-options-all-options-overlay {
    opacity: 1;
    visibility: visible;
  }
}

.sort-modal-select-options-all-options {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  border-radius: 20px;
  background: linear-gradient(115.6deg, #ffffff -3.22%, #ffffff 102.21%);
  box-shadow: 0px 14px 40px rgba(17, 29, 41, 0.4);
  max-height: 225px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 5;
}

.sort-modal-select-options-all-options-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  z-index: 4;
}

.sort-modal-select-options-single-option {
  cursor: pointer;
  user-select: none;
  color: #0d1444;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  transition: all ease-in-out 0.3s;
  &:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  &:last-of-type {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  &-selected {
    color: #2894ff;
    background: #f4f4f4;
  }
}
