@import '../../assets/styles/abstracts/variables';

.vault_wrapper {
  & :global(.whs__header) {
    border-bottom: 1px solid #d9d9d9 !important;
  }
}

.vault {
  &__header {
    a {
      font-weight: 600;
      @media (max-width: 360px) {
        font-size: 10px;
      }
      @media (max-width: 320px) {
        font-size: 9px;
      }
    }
  }

  &__buttons {
    position: fixed;
    left: 0;
    bottom: 7rem;
    width: 100%;
    height: 11.7rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #ffffff 10%, rgba(215, 194, 194, 0) 80.43%);

    &__trashbin {
      width: 5rem;
      height: 5rem;
      background-color: #f1f1f2;
      border-radius: 1rem;
      border: none;
      outline: none;
      margin-right: 0.8rem;

      & img {
        width: 40%;
        height: 40%;
        object-fit: cover;
        object-position: center;
      }
    }

    &__sendTo {
      width: 20rem;
      height: 5rem;
      background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
      border-radius: 2.5rem;
      color: #ffffff;
      line-height: 2rem;
      font-size: 1.6rem;
      font-family: $montserrat;
      font-weight: 600;
      border: none;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        margin-right: 1.5rem;
      }
    }
  }
}

.shared_media_add_to_button {
  position: fixed;
  bottom: 100px;
  right: 20px;
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  height: 100%;
  background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
  border: 3px solid #76bcff;
  box-shadow: 0px 4px 14px rgba(47, 152, 254, 0.5);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.shared_media_drawer {
  position: fixed;
  left: 0;
  right: 0;
  border-radius: 40px;
  bottom: 0;
  height: 180px;
  padding-bottom: 70px;
  background: #15161b;
  transition: all ease-in-out 0.3s;
  transform: translate(0, 100%);
  overflow: hidden;
  z-index: 10;
  &_open {
    transform: translate(0, 0);
  }
}

.shared_media_drawer_inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  // flex-wrap: wrap;
  height: 100%;
  padding: 15px;
}

.shared_media_button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 17px;
  @media (max-width: 250px) {
    margin: 0 10px;
  }
}

.shared_media_drawer_button {
  background: #262a33;
  border-radius: 21px;
  width: 50px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shared_media_drawer_button_text {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  margin-top: 10px;
  font-size: 12px;
}

.select_category_container {
  background: #ffffff;
  border: 1px solid rgba(38, 42, 51, 0.2);
  border-radius: 15px;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.select_category_icon {
  width: 30px;
  height: 30px;
  background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select_category_text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #828c94;
  margin-left: 10px;
}

.add_to_layout {
  & :global(.header) {
    background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
    color: #070707;

    & :global(.header__linkback) {
      &::after {
        background-color: #070707;
      }
      &::before {
        background-color: #070707;
      }
    }
  }

  & :global(.content__wrapper) {
    background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
    & :global(.whs) {
      background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
    }
  }

  & :global(.footer__wrapper) {
    display: none;
  }
}

.add_to_category_button {
  width: 60px;
  height: 60px;
  background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
  border: 3px solid #76bcff;
  box-shadow: 0px 4px 14px rgba(47, 152, 254, 0.5);
  border-radius: 160px;
  position: fixed;
  right: 20px;
  bottom: 100px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.cards_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.cards_container_drawer_open {
  padding-bottom: 110px;
}

.modal_share {
  & :global(.modal__card) {
    max-width: 275px;
    width: 100%;
  }
}
