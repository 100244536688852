@import '../../assets/styles/abstracts/variables';

.verify_email {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    line-height: 2rem;
    font-size: 1.6rem;
    font-family: $montserrat;
    font-weight: 600;
  }

  .go_home_button {
    width: 20rem;
    height: 5rem;
    background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
    border-radius: 2.5rem;
    color: #ffffff;
    line-height: 2rem;
    font-size: 1.6rem;
    font-family: $montserrat;
    font-weight: 600;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  &_layout {
    height: 100vh;
  }
}
