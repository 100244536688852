.header_wrapper {
  & :global(.whs__wrapper) {
    display: flex;
    flex-direction: column;
  }
  & :global(.whs__header) {
    border-color: #d9d9d9;
  }
}

.bundles_card {
  & :global(.subsOptions__card__head) {
    align-items: stretch;
  }
}

.no_price_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  a {
    text-decoration: none;
  }
}

.no_price_text {
  margin-top: 13px;
  margin-bottom: 30px;
  color: #cfd0da;
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}

.no_price_link {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 15px 30px;
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
  box-shadow: 0px 10px 10px rgba(78, 172, 249, 0.2);
  border-radius: 160px;
}
