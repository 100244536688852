@import '../../../../assets/styles/abstracts/variables';

.subsOptions {
  &__cards {
    margin-top: 2rem;
  }

  &__card {
    position: relative;
    margin-bottom: 1rem;
    border-radius: 20px;
    background: $white89deg;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    transition: 0.2s max-height;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100px;
    &--selected {
      border: 2px solid #008aff;
    }
    &--expanded {
      max-height: 100vh;
      transition: 0.4s max-height;
    }

    &__selected {
      @include flex-center;
      position: absolute;
      top: -0.6rem;
      right: -0.4rem;
      background-color: #008aff;
      width: 2.6rem;
      height: 2.6rem;
      border-radius: 50%;
      border: 3px solid #fff;
      img {
        filter: brightness(100);
      }
    }

    &__head {
      display: flex;
      align-items: center;
      padding: 1rem;
    }

    &__icon {
      @include flex-center;
      height: 8rem;
      width: 8rem;
      margin-right: 1.5rem;
      background: #f7f7f7;
      border-radius: 20px;

      &--add {
        height: 2.8rem;
      }

      &--plan {
        height: 4rem;
      }
    }

    &__info {
      flex: 1;
      &__plan {
        @include montserrat-14-semi-bold;
        margin-bottom: 3px;
        font-weight: 600;
        color: #0d1444;
      }

      &__price {
        display: flex;
        align-items: center;
        p {
          @include montserrat-22-bold;
          margin: 0;
          font-weight: 600;
          color: #008aff;
        }
        span {
          @include montserrat-12-normal;
          font-weight: 500;
          color: #afafaf;
        }
      }

      &__discount {
        border: 1px solid #b4daff;
        border-radius: 5px;
        border: 1px solid rgba(180, 218, 255, 1);
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        color: #3199fe;
        margin-left: 8px;
        padding: 3px;
      }

      &__monthly {
        @include montserrat-12-normal;
        margin: 0;
        font-weight: 500;
        color: #afafaf;
      }

      &__add {
        @include montserrat-14-semi-bold;
        font-weight: 600;
        cursor: pointer;
        color: #afafaf;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      &__chevron {
        @include flex-center;
        margin-left: 2rem;
        height: 3rem;
        width: 3rem;
        background: #f7f7f7;
        border-radius: 10px;
        img {
          transform: rotate(0);
          transition: 0.2s all;
        }

        &--active {
          img {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &__body {
      margin-top: 10px;
      padding: 0 3rem 2rem 3rem;
      opacity: 0;
      pointer-events: none;
      &--active {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__features {
    border-bottom: 1px solid #d9d9d9;

    h3 {
      @include montserrat-14-semi-bold;
      color: #afafaf;
    }

    &__feature {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      img {
        border: 3px solid #f0f0f0;
        border-radius: 50%;
        padding: 0.5rem;
        margin-right: 1.5rem;
      }

      p {
        @include montserrat-14-normal;
        font-weight: 500;
        margin: 0;
        color: #0d1444;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
    &__buttons {
      display: flex;
      div {
        border: 1px solid #f4f4f4;
        margin-left: 1rem;
      }
      &-toggleHidden {
        margin-left: auto;
      }
    }
  }

  &__tabs {
    @include montserrat-14-semi-bold;
    display: flex;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid #d9d9d9;
    color: #0d1444;

    &__tab {
      display: flex;
      align-items: center;
      margin-right: 1rem;

      &__radio {
        margin-right: 1rem;
      }

      &--inactive {
        opacity: 0.5;
      }
    }
  }
  &__fields {
    margin-top: 2.5rem;
    padding-top: 2.5rem;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    &__validation {
      @include montserrat-12-normal;
      margin: 0 0 1.8rem 0;
      text-align: right;
      color: #757576;

      &.disabled {
        color: #b0b0b0;
      }

      &.reachedLimit {
        span {
          color: red;
        }
      }

      &.invalidPrice {
        color: red;
      }

      span {
        @include montserrat-14-semi-bold;
      }
    }

    &__option {
      margin-bottom: 2rem;
      .iconbutton {
        background: #262a33;
      }
    }

    &__inputs {
      display: flex;
      width: 100%;
      .inputcard:first-of-type {
        margin-right: 1rem;
      }
      .inputcard:last-of-type {
        margin-left: 1rem;
        width: 50%;
      }
    }

    &__discount {
      p {
        @include montserrat-14-semi-bold;
        font-weight: 600;
      }

      &__buttons {
        display: flex;
        margin-bottom: 2.5rem;
        flex-wrap: wrap;
        .button {
          margin-right: 1rem;
          margin-top: 1rem;
        }
      }

      &__custom {
        display: flex;
        align-items: center;
        height: 7rem;
        background: $white89deg;
        border: 1px solid #dddddd;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        margin-bottom: 2.5rem;
        padding: 2rem;
        @media (max-width: 360px) {
          flex-wrap: wrap;
          height: auto;
          &__price {
            flex: 1;
            p {
              text-align: center;
            }
          }
        }
        p {
          color: #b0b0b0;
          flex: 1;
        }

        &__price {
          display: flex;
          align-items: center;

          p {
            @include montserrat-14-semi-bold;
            font-weight: 600;
            color: #0d1444;
            margin: 0 2.4rem 0 1.6rem;
            width: 2rem;
          }

          .iconbutton {
            border: 1px solid #f4f4f4;
          }
        }
      }
    }

    &__plan {
      background: $white89deg;
      border-radius: 20px;
      border: 1px solid #d9d9d9;
      margin-bottom: 2rem;

      &__head {
        display: flex;
        align-items: center;
        width: 100%;
        height: 7rem;
        padding: 1.5rem;
      }

      &__img {
        @include flex-center;
        background: #262a33;
        border-radius: 10px;
        height: 4rem;
        width: 4rem;
        margin-right: 1.5rem;
      }

      &__selected {
        @include montserrat-14-semi-bold;

        span {
          color: #2f98fe;
        }
      }

      &__placeholder {
        @include montserrat-14-normal;
        color: #757576;
      }

      &__body {
        @include montserrat-14-semi-bold;
        padding: 1.5rem;

        &__item {
          padding: 1rem 0;
        }

        span {
          color: #2f98fe;
        }
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.5rem;
    div {
      margin-left: 1rem;
    }
  }

  &__dropdown {
    width: 50%;
    margin-right: 1rem;
    border-radius: 20px;
    position: relative;
    background: #fff;
    border: 1px solid #dddddd;
    height: 7rem;

    &--active {
      border-radius: 20px 20px 0 0;
      border-bottom: none;
    }

    &__head {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 7rem;
      border-radius: 20px;
      background: transparent;
      padding: 2rem;

      &--title {
        @include montserrat-12-normal;
        font-weight: 500;
        margin: 0;
        color: #b0b0b0;
      }

      &--length {
        @include montserrat-14-semi-bold;
        color: #0d1444;
        margin: 0.5rem 0 0 0;
      }
    }

    &__body {
      @include montserrat-14-semi-bold;
      position: absolute;
      padding: 0 2rem;
      color: #0d1444;
      background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
      width: calc(100% + 2px);
      margin-left: -1px;
      z-index: 1;
      border: 1px solid #dddddd;
      border-top: none;
      border-radius: 0 0 20px 20px;
    }

    &__item {
      display: flex;
      align-items: center;
      height: 5rem;
    }
  }
}

.subsOption__card__wrapper {
  position: relative;
}
