.avatar_container {
  display: flex;
  align-items: center;
  padding-right: 13px;
}

.avatar_circle {
  overflow: hidden;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: -13px;
  flex-shrink: 0;
  flex-grow: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.remaining_avatars {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: linear-gradient(140.46deg, #93ceff 14.38%, #2894ff 94.98%);
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12px;
  margin-right: -13px;
}
