@import '../../assets/styles/abstracts/variables';

.wrapper {
  border-top-left-radius: 4rem;
  border-top-right-radius: 4rem;
  background: $white180deg;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100%;
  padding-top: 2rem;
}
.navigation {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  justify-content: flex-start;
}
.content {
  width: 100%;
  flex: 1;
  padding: 2rem 1rem;
}
.loader {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
