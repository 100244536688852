@import '../../../assets/styles/abstracts/variables';

.livechat {
  position: relative;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  -webkit-mask-image: linear-gradient(to bottom, transparent 5%, black 60%);
  mask-image: linear-gradient(to bottom, transparent 5%, black 60%);

  &__messages {
    overflow: scroll;
    margin-bottom: 2rem;
  }
  &__message {
    display: flex;
    margin-bottom: 2.4rem;

    &__avatar {
      width: 4rem;
      height: 4rem;
      margin-right: 2rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
      }
    }

    &__content {
      max-width: 25rem;
      &--name {
        @include montserrat-14-semi-bold;
        line-height: 150%;
        color: #fff;
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tippedFlag {
          @include montserrat-12-semi-bold;
          display: block;
          background: linear-gradient(180deg, #eb6079 0%, #ff4b77 100%);
          border-radius: 5px;
          color: #ffced7;
          line-height: 150%;
          padding: 0 0.5rem;
          margin-left: 1.4rem;

          span {
            color: #fff;
          }
        }
      }
      &--text {
        @include montserrat-14-normal;
        word-wrap: break-word;
        color: #fff;
        line-height: 120%;
        background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
        border-radius: 0px 2rem 2rem 2rem;
        padding: 1rem;
      }
    }

    &.highlighted {
      .livechat__message__content {
        &--text {
          background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &--left {
      padding-right: 2rem;
    }
    &--right {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &__input {
      position: relative;
      width: calc(100% - 7rem);
      height: 5rem;
      background: rgba(255, 252, 252, 0.15);
      border: 1px solid rgba(38, 42, 51, 0.2);
      border-radius: 15px;
      padding: 0.1rem;
      margin-right: 1rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--icon {
        position: absolute;
        left: 1.5rem;
      }

      &--replyto {
        @include montserrat-14-semi-bold;
        color: #2894ff;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 1rem;
        height: 100%;
        z-index: 26;
      }

      .emojiPicker {
        z-index: 1;
        margin-bottom: 1rem;
      }

      input {
        @include montserrat-14-normal;
        background: transparent;
        padding: 1rem 1rem 1rem 5rem;
        font-weight: 500;
        width: 100%;
        height: 100%;
        border: none;
        color: #fff;
        position: relative;
        border-radius: 15px;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #cfcfcf;
        }
      }
    }
    &__emoji {
      position: absolute;
      right: 1rem;
      top: 1rem;
      height: 2.6rem;
      width: 2.6rem !important;
    }

    &__send {
      @include flex-center;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background: $blue92deg;
      border: 3px solid #76bcff;
    }
  }
}
