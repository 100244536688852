@import '../../../assets/styles/abstracts/variables';

.showMore {
  color: #fff;
  position: absolute;
  z-index: 5;
  padding: 2.7rem 9rem 0 5rem;

  .content-css {
    a {
      @include montserrat-14-semi-bold;
      color: #fff;
      text-decoration: none;
    }
  }

  &--post {
    width: 100%;
    padding: 0.4rem 1rem 0 1.5rem;
    position: relative;

    .showMore__title {
      justify-content: space-between;
      &--name {
        @include montserrat-14-semi-bold;
        color: #0d1444;
      }
    }

    .content-css {
      color: #0d1444;
      a {
        @include montserrat-14-semi-bold;
        color: #0d1444;
        text-decoration: none;
      }
      .my-anchor-css-class {
        color: #285f95;
        p {
          margin: 0;
        }
      }
    }
  }

  &__icon {
    display: inline-block;
    position: absolute;
    top: 3.5rem;
    left: 2.1rem;
    transition: all 0.2s ease-in-out;
    transform-origin: top center;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 1.1rem;

    &--name {
      @include montserrat-16-semi-bold;
      margin-right: 1rem;
      color: inherit;
      text-decoration: none;
    }
    &--time {
      @include montserrat-12-normal;
      color: #bfbebe;
    }
  }

  .content-css {
    @include montserrat-14-normal;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 140%;
  }
  .my-anchor-css-class {
    @include sf-14-normal;
    color: #ffffff;
  }
}

.previewModal {
  .showMore {
    z-index: 6;
  }
}

a {
  text-decoration: none;
}
