@import '../../../../assets/styles/abstracts/variables';

a {
  text-decoration: none;
  color: inherit;
}

.wrapper {
  width: 28.5rem;
}

.media,
.text,
.poll {
  @include montserrat-16-semi-bold;
  position: relative;
  width: 100%;
  height: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 2rem;
  overflow: hidden;
  border: 0.3rem solid transparent;
  margin-bottom: 1rem;
  word-break: break-all;
  background-color: #fff;

  a {
    @include montserrat-16-bold;
    text-decoration: none;
    color: #000000;
  }

  img,
  video {
    width: 100%;
    height: 16rem;
    object-fit: cover;
  }

  .title {
    @include montserrat-18-semi-bold;
    color: #0d1444;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
  }
  .subtitle {
    @include montserrat-12-normal;
    color: #b0b0b0;
  }
}

.poll {
  align-items: start;
}

.poll > div {
  width: 100%;
  height: 16rem;
}

.sidebar {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

.options {
  display: flex;
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem;
  border-radius: 1.1rem;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 3;
}
