.delete-category-modal-container {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
}

.delete-category-modal-text {
  font-size: 16px;
  width: 100%;
}

.delete-category-name {
  font-weight: 600;
  width: 100%;
}

.delete-category-modal-button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 25px;
}

.delete-category-modal-button {
  margin-left: 10px;
  cursor: pointer;
  transition: opacity ease-in-out 0.3s;
  user-select: none;
  &-loading {
    pointer-events: none;
    opacity: 0.7;
    cursor: default;
  }
}
