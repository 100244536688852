@import '../../../../assets/styles/abstracts/variables';

.modal__card {
  overflow-x: hidden;
  position: relative;
}

.addUsers__modal {
  display: flex;
  flex-direction: column;
  &__actionCardContainer {
    overflow-y: auto;
    overflow-x: hidden;
    height: 33rem;
    padding-bottom: 100px;
    .actionCard--avatar {
      border-radius: 50%;
    }
  }
  &__borderLine {
    width: 100%;
    height: 1px;
    background-color: #d9d9d9;
    margin: 2rem 0;
  }
  &__avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    display: block;
  }
  &__selectAll {
    display: flex;
    align-items: center;

    &--p {
      margin-left: 1rem;
      @include montserrat-12-normal;
      color: #828c94;
    }
  }
  &__bottomArea {
    height: 9.6rem;
    width: 100%;
    background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
    border-radius: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &--btn {
      transition: opacity ease-in-out 0.3s;
    }
    &--wrapper {
      position: sticky;
      left: 0;
      bottom: 0;
      height: 9.6rem;
      width: calc(100% + 6rem);
      transform: translate(-3rem, 2rem);
      background-color: #ffffff;
    }
  }
}

.fans-group-action-card.actionCard.actionCard--withouticon {
  padding: 1.5rem 1rem;
}

.add__users__modal-custom .modal__card {
  background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
  height: 65rem;
}

.add-users-modal-wrapper {
  .modal-wrapper-card {
    padding: 2rem 3rem;
  }
}

.add-users-filter-sort-title {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.02em;
  font-size: 1.8rem;
  line-height: 2.2rem;
  position: relative;
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-weight: 600;
}

.add-users-button-loading {
  opacity: 0.7;
  user-select: none;
  pointer-events: none;
}

.add-users-active-filter {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #262a33;
  border-radius: 10px;
  padding: 6px 12px;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;
  font-size: 12px;
  margin-right: auto;
  span {
    font-weight: 600;
    margin-left: 5px;
  }
  margin-top: 20px;
}

.add-users-active-filter-close {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ffffff;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  user-select: none;
}

.add-users-loader-container {
  height: 100%;
  width: 100%;
  padding-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
