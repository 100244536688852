.card_title {
  font-family: 'Montserrat', sans-serif;
  color: #0d1444;
  font-weight: 600;
  font-size: 14px;
}

.duration {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  color: #afafaf;
  span {
    color: #0d1444;
    font-weight: 600;
  }
}

.description_container {
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  font-family: 'Montserrat', sans-serif;
  * {
    font-family: inherit;
  }
}

.description_title {
  color: #afafaf;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.description_date_container {
  display: flex;
  width: 100%;
  align-items: center;
}

.description_date {
  color: #afafaf;
  font-size: 12px;
  margin-right: 20px;
  margin-bottom: 15px;
  span {
    color: #0d1444;
  }
  @media (max-width: 365px) {
    margin-right: 10px;
    font-size: 11px;
  }
}

.description_limit {
  display: flex;
  font-size: 14px;
  color: #0d1444;
  margin-bottom: 30px;
  span {
    margin-left: 10px;
  }
}

.description_options {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.description_copy_link {
  cursor: pointer;
  width: 100px;
  height: 36px;
  background-color: transparent;
  border: 1px solid #000000;
  border-radius: 160px;
  font-size: 14px;
  color: #131318;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.description_copy_link_loading {
  pointer-events: none;
  opacity: 0.5;
}

.filter_tabs {
  padding: 5px;
  display: flex;
  background: #f5f5f6;
  border-radius: 20px;
  height: 40px;
  max-width: 285px;
  margin-bottom: 20px;
}

.filter_tab {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #757576;
  flex: 1;
  border-radius: 20px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  &_active {
    color: #ffffff;
    background: #262a33;
  }
}

.description_claimed_by {
  span {
    color: #0d1444;
    margin-left: 3px;
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.description_claimed_by_text {
  color: #afafaf;
  font-size: 12px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.stacked_img_container {
  margin-left: 10px;
  margin-bottom: 2px;
  margin-top: 2px;
}

.stacked_img {
  div {
    width: 24px;
    height: 24px;
    margin-right: -12px;
  }
}
