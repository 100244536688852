@import '../../../../assets/styles/abstracts/variables';

.editPreview {
  .modal__card__withoutTitle__close {
    display: none;
  }
}

.editPreviewModal {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
  z-index: 31;
  display: flex;
  flex-direction: column;
  &__top {
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 2rem;
    position: relative;

    &__cover {
      border-radius: 3rem;
      overflow: hidden;
      position: relative;
      height: 22.5rem;
      img,
      video {
        object-fit: cover;
        width: 100%;
        height: 22.5rem;
      }
    }
  }
  &__player {
    &__icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 4.5rem;
        width: 5rem;
      }

      &--playing {
        animation: vanish 0.8s forwards;
      }
      &--notPlaying {
        animation: appear 0.3s forwards;
      }
    }
  }
  &__circle {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: rgba($color: #dbdbdb, $alpha: 0.5);
    z-index: 11;

    svg {
      position: relative;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 50%;
      background: #ffffff;
    }
  }
  &__content {
    flex: 1;
    overflow-y: hidden;
    background: linear-gradient(180deg, #ffffff 12.4%, #fbfbfb 99.26%);
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2.3rem 2rem 1.2rem 2rem;
      &__title {
        @include montserrat-18-semi-bold;
        color: #0d1444;
      }
      &__toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          @include montserrat-12-semi-bold;
          color: #b0b0b0;
          margin: 0 1rem 0 0;
        }
      }
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 1rem;
      padding: 0 1rem 12rem 1rem;
      overflow-y: auto;

      &--disabled {
        pointer-events: none;
        opacity: 0.3;
      }

      &__item {
        width: 100%;
        height: 10.6rem;
        border-radius: 1rem;
        overflow: hidden;
        position: relative;

        &__check {
          width: 2.2rem;
          height: 2.2rem;
          border-radius: 50%;
          border: 2px solid rgba(255, 255, 255, 0.8);
          position: absolute;
          top: 1rem;
          right: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &--active {
          z-index: 2;
          transform: translate3d(0, 0, 0);
          border: 3px solid #76bcff;
          filter: drop-shadow(0px 4px 14px rgba(47, 152, 254, 0.5));
          .editPreviewModal__content__list__item__check {
            z-index: 3;
            transform: translate3d(0, 0, 0);
            background-color: #fff;
          }
        }
        img {
          transform: translate3d(0, 0, 0);
          width: 100%;
          height: 10.6rem;
          object-fit: cover;
        }
      }
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 11;
    background-color: #ffffff;
    border-top-left-radius: 4rem;
    border-top-right-radius: 4rem;
    display: flex;
    justify-content: center;
    padding: 2.5rem 0 3.4rem;

    &--transparent {
      background-color: transparent;
    }

    .button {
      &:first-of-type {
        margin-right: 2rem;
      }
    }
  }
  &__error {
    position: absolute;
    left: 1rem;
    bottom: -1.5rem;
    @include montserrat-12-normal;
    margin: 0 auto;
    color: red;
  }
  .processingFile__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &--landscape {
    .editPreviewModal__footer {
      bottom: -10rem;
    }
  }
}
.editPreview {
  .modal__card {
    width: 100%;
    height: 100%;
    border-radius: unset;
  }
}
