@import '../../../assets/styles/abstracts/variables';

.iic {
  @include flex-center;

  position: absolute;
  z-index: 1;

  border-radius: 50%;

  &::after {
    content: '.';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;

    border-radius: 50%;
    width: 100%;
    height: 100%;

    background: #000;
    opacity: 0.2;
  }

  &--login {
    top: -6rem;
    left: calc(50% - 6rem);

    height: 12rem;
    width: 12rem;

    &::after {
      background: #fff;
      opacity: 0.5;
    }

    &__container {
      @include flex-center;

      border-radius: 50%;
      height: 10rem;
      width: 10rem;

      background: #fff;
      box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.15);
    }
  }
  &--tipAvatar {
    height: 8rem;
    width: 8rem;
    &__container {
      height: 7.3rem;
      width: 7.3rem;

      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  &--profile {
    top: -6rem;
    left: calc(50% - 7rem);

    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.65);
    height: 14rem;
    width: 14rem;

    &__container {
      @include flex-center;

      border-radius: 50%;
      height: 12rem;
      width: 12rem;

      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    &--tipAvatar {
      height: 8rem;
      width: 8rem;
      &__container {
        height: 7.3rem;
        width: 7.3rem;

        img {
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }

    &--small {
      height: 7.5rem;
      width: 7.5rem;

      &__container {
        height: 6.8rem;
        width: 6.8rem;

        img {
          border-radius: 50%;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }

  &--modal {
    @include flex-center;

    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    width: 6rem;
    height: 6rem;

    background: #dbdbdb80;

    &__container {
      @include flex-center;

      border-radius: 50%;
      width: 4.2rem;
      height: 4.2rem;

      background-color: #fff;
    }
  }

  &--small {
    @include flex-center;
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    overflow: hidden;
    background: $blue92deg;

    &__container {
      @include flex-center;
      height: 4.6rem;
      width: 4.6rem;
      border: 1px solid #fff;
      border-radius: 50%;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        border-radius: 50%;
      }
    }
  }

  &__loader {
    position: absolute;
    z-index: -1;

    border-radius: 50%;
    height: 13rem;
    width: 13rem;

    background-color: #fff;
    overflow: hidden;

    &--top {
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      top: 0.4rem;
      border-radius: 50%;
      background: #93ceff;
    }

    &--left {
      width: 0.8rem;
      height: 0.8rem;
      position: absolute;
      left: 0.4rem;
      border-radius: 50%;
      background: #93ceff;
    }

    &:before {
      content: '.';

      position: absolute;
      top: 0;
      left: 0;

      width: 50%;
      height: 100%;

      background: $blue92deg;

      transform: rotate(-90deg);
      transform-origin: right;
    }

    &:after {
      content: '.';

      position: absolute;
      top: 0;
      right: 0;

      width: 50%;
      height: 100%;

      background: $blue92deg;
    }
  }

  &__camera {
    @include flex-center;

    position: absolute;
    right: 1.4rem;
    bottom: 0.4rem;

    border-radius: 50%;
    height: 3.6rem;
    width: 3.6rem;

    background: #000;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    img {
      border-radius: 0;
      height: 1.8rem;
      width: 1.8rem;
      object-fit: none;
      object-position: center;
    }

    &__cover {
      top: -6rem;
      left: 2rem;
      background: #0000006c;

      img {
        opacity: 0.5;
      }
    }
  }

  &__live {
    @include montserrat-12-bold;
    position: absolute;
    bottom: 0;
    color: #d6ebff;
    background: $black157deg;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    padding: 0.2rem 1rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 0.6rem;
      width: 0.6rem;
      border-radius: 50%;
      background-color: #0fe465;
    }
  }
}
