.container {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  padding-bottom: 25px;
  transition: all ease-in-out 0.3s;
  // z-index: 5;
  // position: relative;
  // pointer-events: none;
  // * {
  //     pointer-events: auto;
  // }
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
}
