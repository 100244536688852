@import '../../assets/styles/abstracts/variables';

.subscriptions {
  &__content__wrapper {
    height: calc(100% - 6rem);
  }

  &__tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &--count {
      @include montserrat-12-light;
      color: #afafaf;

      span {
        @include montserrat-12-semi-bold;
        color: #757576;
      }
    }

    .navbar {
      width: 18.6rem;
    }
  }

  &__model {
    height: 10rem;
    overflow: hidden;
    transition: 0.2s height;
    margin-bottom: 1rem;

    &--active {
      height: 32rem;
    }

    &__head {
      position: relative;
      padding: 1rem;
      border-radius: 20px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      transition: 0.2s border-radius;

      &--active {
        border-radius: 20px 20px 0 0;
      }

      &__actions {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6rem;
        background: #262a3379;
        border-radius: 0 0 20px 20px;
        padding: 0 1rem 0 10rem;
      }

      &__user {
        flex: 1;

        &--name {
          @include montserrat-14-bold;
          color: #fff;
          margin: 0;
        }
        &--username {
          @include montserrat-12-normal;
          font-weight: 500;
          color: #c6c6c6;
          margin: 0;
        }
      }

      &__buttons {
        display: flex;
        align-items: center;

        .iconbutton {
          margin-left: 1rem;
        }

        &--chevron {
          width: 3rem;
          height: 3rem;

          img {
            filter: brightness(100);
            transform: rotate(0);
            transition: 0.2s all;
          }
          &--rotated {
            img {
              filter: brightness(100);
              transform: rotate(-180deg);
            }
          }
        }
      }

      &__radio {
        @include flex-center;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 5rem;
        background: #fff;
        border-radius: 0 20px 20px 0;
      }
    }

    &__body {
      background: $white89deg;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
      height: 0;
      overflow: hidden;
      border-radius: 0 0 20px 20px;
      transition: 0.2s height;
      height: 22rem;
      padding: 2rem;
    }

    &__line {
      @include montserrat-12-normal;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;

      &__item {
        color: #828c94;
      }

      &__value {
        color: #0d1444;
      }

      &__sub {
        color: #3199fe;
        border: 1px solid #3199fe;
        border-radius: 5px;
        padding: 2px;
      }

      &__active {
        color: #10cbbb;
        border: 1px solid #10cbbb;
        border-radius: 5px;
        padding: 2px;
      }
    }
  }
  &__divider {
    height: 1px;
    background: #d9d9d9;
    margin: 2rem 0;
  }
}

.subscriptions__content__wrapper {
  .whs__wrapper {
    padding-bottom: 0;
  }
}

.subscriptionsLists {
  &__addlist {
    @include montserrat-14-bold;
    display: flex;
    align-items: center;
    background: $white89deg;
    color: #afafaf;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-radius: 20px;

    img {
      padding: 1.8rem;
      border-radius: 10px;
      background: #f7f7f7;
      margin-right: 5rem;
    }
  }
}
