@import '../../assets/styles/abstracts/variables';
@media (min-width: 680px) {
  .profilefeed.skeleton {
    width: 68rem;
  }
}
.profilefeed {
  position: relative;
  margin-top: -4rem;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f2f6f9;
  // background: $white180deg;
  // transform: translateY(-4rem);
  min-height: calc(100% - 45rem);
  border-radius: 25px 25px 0 0;
  padding: 2rem 1rem 7rem 1rem;
  // .navbar {
  //   margin-bottom: 1rem;
  // }

  &.extraPad {
    padding-bottom: 13rem;
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  &__noposts {
    @include flex-center;
    @include montserrat-14-normal;
    flex-direction: column;
    margin-top: 10%;
    padding-bottom: 0rem;
    color: #0d1444;
    text-decoration: none;
    &__plus {
      height: 6rem;
      width: 6rem;
      padding: 1.5rem;
      background: $blue140deg;
      border-radius: 20px;
    }
    // font-size: 1.4rem;
    // margin: 0.9rem 0 0 1rem;
    // color: #828c94;
  }

  &__icons {
    display: flex;
    margin-bottom: 1rem;
    &--sort {
      margin-right: 2rem;
    }

    &--filter {
      margin-right: 1rem;
    }
  }
}

.profilefeed-model-no-posts {
  width: 100%;
  padding-top: calc((220 / 391) * 100%);
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.profilefeed-model-no-posts-background {
  background: linear-gradient(140.46deg, #93ceff 14.38%, #2894ff 94.98%);
  opacity: 0.1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  z-index: 1;
}

.profilefeed-model-no-posts-inner {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profilefeed-model-no-posts-add-button {
  width: 58px;
  height: 58px;
  background: linear-gradient(140.46deg, #93ceff 14.38%, #2894ff 94.98%);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilefeed-model-no-posts-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 25px;
  color: #15161c;
  text-decoration: none;
}
