@import '../../assets/styles/abstracts/variables';

.videoPlayer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--minimized {
    width: 14.2rem;
    height: 8rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  &__icon {
    position: relative;
    z-index: 10;
    svg {
      height: 4.5rem;
      width: 5rem;
    }

    &--playing {
      animation: vanish 0.8s forwards;
    }
    &--notPlaying {
      animation: appear 0.3s forwards;
    }
  }
}

@keyframes vanish {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.click__overlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
}
