@import '../../../assets/styles/abstracts/variables';

.creator {
  position: relative;
  &.statsExpanded {
    .creator__wrapper {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .creator__stats {
      display: block;
    }
  }

  &__discount {
    @include montserrat-11-normal;
    color: #3199fe;
    line-height: 0;
    position: absolute;
    right: 1rem;
    top: -0.5rem;
    border-radius: 0.5rem;
    padding: 0.6rem 0.4rem 0.8rem;
    background: #ffffff;
    border: 1px solid #b4daff;
    z-index: 3;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: 10rem;
    border-radius: 2rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    overflow: hidden;

    &--expired {
      padding: 0;

      .creator__avatar {
        margin-left: 1rem;
      }
    }
  }
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  &__avatar {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0.6rem;
        top: 1rem;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background: #0fe465;
      }
    }

    img {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #ffffff;
    }
  }
  &__live {
    position: absolute;
    bottom: -0.4rem;
    left: 50%;
    transform: translateX(-50%);
    background: #ff284f;
    border-radius: 0.5rem;
    padding: 0.2rem 0.5rem;

    span {
      @include montserrat-10-semi-bold;
      color: #ffffff;
      opacity: 0.9;
    }
  }
  &__text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6rem;
    padding-left: 10.4rem;
    display: flex;

    &--bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #262a33;
      opacity: 0.5;
    }
    &--wrapper {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      overflow: hidden;
      flex-shrink: 1;
    }
    &--name {
      @include montserrat-14-semi-bold;
      @include text-ellipsis;
      color: #fff;
      width: auto;
    }
    &--handle {
      @include montserrat-12-normal;
      @include text-ellipsis;
      color: #c6c6c6;
      width: auto;
    }
    &--expired {
      @include sf-12-light;
      color: #ffffff;
    }
    &--subscribed {
      display: flex;
      align-self: center;
      margin-right: 1.1rem;
      background: linear-gradient(157.59deg, #18192048 0%, #0f0f1348 100%);
      border-radius: 0.6rem;
      z-index: 1;

      img {
        filter: brightness(1000%);
        flex-grow: 0;
        object-fit: contain;
        width: 1.6rem;
        height: 1.6rem;
        margin: 0.4rem 0.5rem;
        margin-right: 0;
      }

      span {
        margin: 0.4rem;
        margin-left: 0.5rem;
        color: #fff;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 1.1rem;
        line-height: 150%;
      }
    }
  }

  &__stats {
    display: none;
    padding: 2.2rem 2rem 1.7rem 2rem;
    background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;

    &__referrals {
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      .creator__stat {
        margin-bottom: 0 !important;
        gap: 0.7rem;
      }
    }
  }
  &__stat {
    @include montserrat-12-normal;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 1.5rem;
    }

    &__name {
      color: #828c94;
    }
    &__value {
      color: #0d1444;

      span {
        @include montserrat-12-bold;
      }

      &--status {
        padding: 0.5rem;
        border-radius: 5px;
      }
      &--active {
        color: #10cbbb;
        border: 1px solid #a1e7e1;
      }
      &--expired {
        color: #ff4b55;
        border: 1px solid #ff4b55;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;

    &__button {
      background: rgba(38, 42, 51, 0.8);
      border: 1px solid #525967;
      border-radius: 1rem;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      position: relative;

      &--message {
        svg {
          width: 1.7rem;
          height: 1.7rem;
        }
      }

      &--small {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  &__topinfo {
    display: flex;
    align-items: center;
    position: absolute;
    top: 1.2rem;
    right: 1rem;

    &__item {
      @include montserrat-10-semi-bold;
      color: #fff;
      height: 1.6rem;
      padding: 0 0.3rem;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;

      &:not(:last-of-type) {
        margin-right: 0.5rem;
      }

      span {
        @include montserrat-10-bold;
        display: inline-block;
        margin-right: 0.3rem;
      }

      p {
        margin: auto;
        position: relative;
      }

      &__bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        &--blue {
          background: #2894ff;
        }
        &--trans {
          background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
          opacity: 0.3;
        }
      }
    }
  }
  &__expired {
    padding-left: 1.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    &__text {
      position: relative;
      flex: 1;

      &--name {
        margin-top: 0;
        margin-bottom: 0.5rem;
      }
      &--handle {
        line-height: 1.8rem;
        margin-top: 0;
        margin-bottom: 0.4rem;
      }
      &--date {
        margin: 0;
        line-height: 1.8rem;
      }
    }

    &__actions {
      padding: 1.6rem 1rem 1.8rem 0;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      &--bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
        width: 15.1rem;
        background-color: rgba(0, 0, 0, 0.5);
      }

      &--price {
        @include montserrat-16-semi-bold;
        color: #fff;
        position: relative;
        align-self: flex-end;

        span {
          @include montserrat-12-normal;
        }
      }
    }

    &--bg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #262a33;
      opacity: 0.6;
    }
  }
  &__select {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkboxField {
      position: relative;
      z-index: 5;
      &__box {
        margin-right: 0;
      }
    }
  }
}
