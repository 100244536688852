@import '../../../assets/styles/abstracts/variables';

.tutorialCard {
  display: flex;
  height: 14rem;
  padding: 1rem;
  background: $white89deg;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 1rem;

  &__image {
    flex: 1;
    position: relative;
    margin-right: 1rem;
    border-radius: 10px;
    background-size: cover;
    background-position: center;

    &__playbtn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 2.1rem;
      height: 2.1rem;
      background: #fff;
      border-radius: 10px 0 0 0;

      &__bg {
        @include flex-center;
        position: absolute;
        bottom: 0;
        right: 0;
        background: #028dfd;
        border-radius: 50%;
        padding: 0.8rem;
        height: 1rem;
        width: 1rem;

        img {
          height: 0.6rem;
          width: 0.6rem;
        }
      }
    }
  }

  &__info {
    flex: 2;

    &__title {
      @include montserrat-14-bold;
      color: #0d1444;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &__text {
      @include montserrat-14-normal;
      font-weight: 500;
      color: #828c94;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
