@import '../../../assets/styles/abstracts/variables';

.verify {
  background-color: black;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background: linear-gradient(113.73deg, #0f0f13 0%, #2e2f39 36.98%, #0f0f13 69.46%);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 4rem;
  z-index: 5;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.active {
    height: 14.5rem;
    padding: 2.1rem 4rem 0 3rem;
  }
}

.left__side--wrapper {
  display: flex;
}

.verify__text {
  margin-left: 2rem;
  a {
    @include montserrat-14-semi-bold;
    color: #2894ff;
    display: block;
    text-decoration: none;
  }
  p {
    @include montserrat-12-semi-bold;
    color: #9095b3;
    margin: 0;
  }
}

.arrow__flashing {
  &.first {
    animation: arrowFlashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &.second {
    animation: arrowFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  &.third {
    animation: arrowFlashing 1s infinite alternate;
    animation-delay: 1s;
  }
}

@keyframes arrowFlashing {
  0% {
    opacity: 1;
  }
  // 50% {
  //   opacity: 0.7;
  // }
  100% {
    opacity: 0.3;
  }
}
