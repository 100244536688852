@import '../../../assets/styles/abstracts/variables';

.pollPreview {
  display: flex;
  flex: 1;
  padding: 2rem;

  &__wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5%;
    border-radius: 2rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
  .poll {
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 33rem;
    min-width: 29rem;

    &__header {
      position: relative;
      padding: 2.2rem 0;
      @include montserrat-18-semi-bold;
      color: #ffffff;
      text-align: center;
      width: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      position: relative;

      p {
        position: relative;
        margin: 0;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
        opacity: 0.7;
      }
    }

    &__content {
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 2rem;
      overflow: auto;

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #ffffff;
        opacity: 0.8;
      }
    }
    &__option {
      background: #ffffff;
      padding: 1.3rem;
      border: none;
      border-radius: 1.5rem;
      display: flex;
      justify-content: flex-start;
      min-height: 4.5rem;
      align-items: center;
      position: relative;
      flex: 1;

      input[type='radio'] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }

      // &--empty {
      //   .poll__option__icon {
      //     border: 1px solid #bcbcbc;
      //     color: #bcbcbc;
      //   }
      // }
      // &--add {
      //   border: 1px dashed #d6d6d6;
      //   .poll__option__icon {
      //     border: 1px solid #bcbcbc;
      //     color: #bcbcbc;
      //     font-size: 2rem;
      //   }
      // }

      &__icon {
        @include montserrat-11-normal;
        position: absolute;
        left: 3%;
        top: 50%;
        transform: translateY(-50%);
        max-width: 2.6rem;
        max-height: 2.6rem;
        border: 1px solid #0d1444;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.2rem;
      }
      &__input {
        @include montserrat-14-semi-bold;
        color: #0d1444;
        width: 100%;
        background-color: transparent;
        border: none;
        box-shadow: none;
        position: relative;
        text-align: center;
        flex: 1;
      }
      &__text {
        @include montserrat-14-semi-bold;
        color: #bcbcbc;
      }
    }
  }
  .yesNoQuestion {
    &__button {
      border: none;
    }
    &__input {
      @include montserrat-22-semi-bold;
      color: #fff;
      text-align: center;
    }
  }
  .askQuestion {
    &__header {
      @include montserrat-22-semi-bold;
      color: #fff;
      text-align: center;
      p {
        margin: 0;
        position: relative;
      }
    }
  }
}
