@import '../../../assets/styles/abstracts/variables';

.circleAvatar {
  width: 8.1rem;
  height: 8.1rem;
  position: relative;
  display: inline-block;
  background: #5a606e;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.65);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  span {
    width: 0.5rem;
    height: 0.5rem;
    background: #93ceff;
    border-radius: 50%;
    position: absolute;
    z-index: 4;

    &:nth-of-type(1) {
      left: 2px;
      transform: translateY(50%);
      bottom: 50%;
    }
    &:nth-of-type(2) {
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__inner {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, #4dabf8 0%, #2e97fe 100%);
      position: absolute;
      bottom: 0;
    }
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 100%;
      background: linear-gradient(180deg, #4dabf8 0%, #2e97fe 100%);
      position: absolute;
      right: 0;
    }

    img {
      display: block;
      width: 6.9rem;
      height: 6.9rem;
      border-radius: 50%;
      object-fit: cover;
      position: relative;
      z-index: 3;
    }
  }
}
