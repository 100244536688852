.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat';
  // margin: 0 5px;
  * {
    font-family: inherit;
  }
}

.avatar {
  width: 60px;
  height: 60px;
  margin-bottom: 4px;
  padding: 2px;
  border-radius: 100%;
  border: 2px solid #d6d6d6;
  background-color: white;
  cursor: pointer;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.user_name {
  font-size: 10px;
  font-weight: 400;
  color: #828c94;
  text-decoration: none;
  line-height: 12px;
  max-width: 60px;
  text-align: center;
  cursor: pointer;
}
