@import '../../../../assets/styles/abstracts/variables';

.goalPreview {
  padding: 2rem;
  display: flex;
  flex: 1;

  &__wrapper {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5%;
    border-radius: 2rem;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.goalBox {
  border-radius: 2rem;
  overflow: hidden;
  flex: 1;

  &__header {
    position: relative;
    padding: 2.2rem 0;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
      opacity: 0.7;
    }
    input {
      @include montserrat-18-semi-bold;
      color: #ffffff;
      text-align: center;
      width: 100%;
      background-color: transparent;
      border: none;
      box-shadow: none;
      position: relative;
      opacity: 1;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: #ffffff;
        opacity: 0.5;
      }
    }
  }
  &__content {
    background-color: rgba($color: #fff, $alpha: 0.9);
    padding: 2rem 1rem 2.5rem;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 0.7rem 1.5rem;
    border-radius: 2rem;
    background-color: #fff;
    overflow: hidden;
  }

  &__percentage {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, #b1dbff, #b1dbff), linear-gradient(0deg, #ffffff, #ffffff), #f2f6f9;
  }

  &__amount {
    @include montserrat-18-bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    z-index: 2;
    color: #0d1444;

    &__group {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    span {
      @include montserrat-20-light;
      line-height: 36px;
    }

    small {
      @include montserrat-12-normal;
      color: #6b806f;
    }
  }

  &__options {
    &__title {
      @include montserrat-16-semi-bold;
      padding: 1.4rem 0 1.7rem 0;
      text-align: center;
    }
    &__list {
      display: flex;
      justify-content: center;
      align-items: center;

      &--disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  &__option {
    @include montserrat-16-semi-bold;
    width: 7rem;
    height: 3.6rem;
    border: 1px solid #000000;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0d1444;

    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
}
