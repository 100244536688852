@import '../../../assets/styles/abstracts/variables';

.newMessage {
  display: flex;
  flex-direction: column;
  // height: 100%;
  border-radius: 25px;
  background: $white90deg;

  &__targets {
    display: flex;
    align-items: center;
    &__groups {
      display: flex;
      flex: 1;
      height: 5rem;
      background: #fff;
      border: 1px solid rgba(38, 42, 51, 0.2);
      border-radius: 15px;
      margin-left: 1rem;
      align-items: center;
      padding-right: 15px;
      padding-top: 16px;
      padding-bottom: 16px;
      &__text {
        color: #828c94;
        font-size: 14px;
        font-family: 'Montserrat', sans-serif;
        margin-right: auto;
      }
      &__icon {
        width: 30px;
        height: 30px;
        background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
      }
    }
  }

  &__create {
    margin-left: 0;
    // height: 40rem;
    bottom: 85px;
  }

  &__writemessage {
    bottom: 7rem;
  }
}

.who-are-you-writing-to-modal-wrapper {
  .modal-wrapper-card {
    background: linear-gradient(90deg, #f6f6f6 0%, #ffffff 51.09%, #efefef 100%), #f2f6f9;
    box-shadow: 0px 20px 40px rgba(17, 29, 41, 0.6);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  &-fan {
    .modal-wrapper-card {
      overflow: visible;
    }
  }
}
