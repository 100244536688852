.message_options_container {
  display: flex;
  align-items: center;
}

.message_options_container_recieved {
  margin-right: auto;
}

.more_options_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: none;
  &_open {
    display: block;
  }
}

.message_options_more_container {
  position: relative;
  &_open {
    .message_options_more_options {
      opacity: 1;
      visibility: visible;
      margin-top: 0;
    }
    .message_options_more_icon {
      svg {
        circle {
          fill: #53aff7;
        }
      }
    }
  }
}

.message_options_more_icon {
  width: 20px;
  height: 25px;
  margin-left: 5px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    transform: rotate(90deg);
    circle {
      transition: fill ease 0.2s;
    }
  }
}

.message_options_more_options {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px #e9e9e9;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity ease 0.2s,
    visibility ease 0.2s,
    margin ease 0.2s;
  z-index: 100;
  margin-top: -5px;
  div {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 20px;
  }
}

.message_options_react {
  position: relative;
  margin-right: 10px;
}

.message_options_react_icon {
  margin-left: 5px;
  width: 16px;
  height: 16px;
}

.message_options_react_emojis {
  position: absolute;
  top: -65px;
  right: 0;
  padding: 10px 5px;
  background: #262a33;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    opacity ease-in-out 0.2s,
    visibility ease-in-out 0.2s,
    margin ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  margin-top: 5px;
  div {
    margin: 0 10px;
  }
  &_open {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  @media (max-width: 360px) {
    width: 160px;
    flex-wrap: wrap;
    top: -95px;
  }
}
