@import '../../../assets/styles/abstracts/variables';
.dialog {
  @include montserrat-14-normal;
  color: #778797;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  text-align: center;
  padding-top: 5.6rem;

  &__icon {
    max-width: 13rem;
    margin-bottom: 1.6rem;

    img {
      display: block;
      width: 100%;
    }
  }

  h3 {
    @include montserrat-24-bold;
    color: #0d1444;
    line-height: 3rem;
    margin: 0;
    margin-bottom: 1.6rem;
  }

  p {
    margin-bottom: 5.6rem;
    max-width: 22rem;
    line-height: 19.6px;
  }
}
