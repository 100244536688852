@import '../../assets/styles/abstracts/variables';

.photoEdit {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 1.8rem 2rem 5rem;
  position: fixed;
  overflow: hidden;

  .hide {
    display: none !important;
  }

  &__header {
    margin-bottom: 2.4rem;

    &__title {
      @include montserrat-22-semi-bold;
      color: #ffffff;
      text-align: center;
    }
  }

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    margin-top: 10rem;

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      z-index: 2;
      width: fit-content;

      &:nth-child(1) {
        margin-bottom: 3rem;
      }

      &--upload {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      .addTextButton__text {
        @include montserrat-12-normal;
        color: #ffffff;
        margin-left: 1rem;
      }

      &--icon {
        width: 3rem;
        height: 3rem;
        border: 1px solid rgba(255, 255, 255, 0.49);
        box-sizing: border-box;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
        border-radius: 1rem;
      }
    }
  }

  &__footer {
    position: absolute;
    bottom: 5rem;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .button {
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}
