.button {
  position: absolute;
  top: 31px;
  right: 21px;
  opacity: 0.5;

  svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}
