@import '../../../assets/styles/abstracts/variables';

.container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  margin-top: 1rem;
  width: 100%;
}

.number {
  @include montserrat-28-normal;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  width: 4.5rem;
  span {
    @include montserrat-12-normal;
    color: #bcbcbc;
  }
}

.list {
  flex: 1;
  list-style: none;
  margin-top: 0;
}
