@import '../../../assets/styles/abstracts/variables';

.searchfield {
  position: relative;

  &.searchActive {
    .searchfield__input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }
  &--noIcon {
    @media (max-width: 360px) {
      .searchfield__icon {
        display: none;
      }
      .searchfield__input {
        padding-left: 15px;
      }
    }
  }
  &__input {
    @include montserrat-14-bold;
    width: 100%;
    height: 5rem;
    font-weight: 500;

    border: 1px solid rgba(38, 42, 51, 0.2);
    border-radius: 15px;
    box-shadow: 0px 4px 14px rgba(162, 162, 162, 0.2);
    box-sizing: border-box;
    background: #fffcfc;
    padding-left: 45px;
    &::placeholder {
      color: #cfcfcf;
    }
    &:focus {
      outline: none;
    }
    @media (max-width: 345px) {
      font-size: 1.2rem;
    }
  }
  &__icon {
    position: absolute;
    top: 1.5rem;
    left: 1.6rem;
  }
  &__icon__close {
    position: absolute;
    top: 2rem;
    right: 1.6rem;
  }

  &__body {
    position: absolute;
    z-index: 6;
    width: 100%;
    max-height: 29.5rem;
    left: 0;
    top: 100%;
    background: #fffcfc;
    border-right: 1px solid rgba(38, 42, 51, 0.2);
    border-bottom: 1px solid rgba(38, 42, 51, 0.2);
    border-left: 1px solid rgba(38, 42, 51, 0.2);
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;

    &__recent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.3rem 2rem 0 2rem;

      p {
        @include montserrat-14-semi-bold;
        color: #0d1444;
        margin: 0;
      }
      span {
        @include montserrat-12-normal;
        letter-spacing: 0.04em;

        color: #2894ff;
      }
    }

    &__divider {
      height: 0.1rem;
      background: #d9d9d9;
      margin: 1.7rem 3.1rem 0 2.1rem;
    }
  }
  &__results {
    max-height: 24rem;
    overflow-y: auto;

    a {
      text-decoration: none;
    }
  }
}
