@import '../../../../assets/styles/abstracts/variables';

.report {
  :global(.button) {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.option {
  @include montserrat-14-semi-bold;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #0d1444;

  &:not(:last-of-type) {
    margin-bottom: 2.4rem;
  }
}

.message {
  margin-top: 2rem;
}

.label {
  flex: 1;
  margin-left: 1.3rem;
}
