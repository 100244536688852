@import '../../../../../assets/styles/abstracts/variables';

.friendReqSentModal__user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;

    svg {
      position: absolute;
      height: 45px;
      bottom: -30px;
    }
  }

  p {
    @include montserrat-14-normal;
    margin-bottom: 20px;
  }

  .friendReqSentModalButton {
    height: 36px;
    width: 138px;
  }
}
