.container {
  display: flex;
  flex-direction: column;
  padding: 30px 20px;
  position: relative;
  font-family: 'Montserrat';
  padding-bottom: 96px;
  * {
    font-family: inherit;
  }
}

.cards_container {
  max-height: 400px;
  min-height: 400px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
}

.title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.title {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: #0d1444;
}

.sort_buttons_container {
  display: flex;
  align-items: center;
  button {
    background-color: transparent;
    border: none;
    padding: 10px;
  }
}

.links_container {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background: #f5f5f6;
  margin-right: auto;
  padding: 5px;
  margin-bottom: 25px;
}

.link_button {
  font-weight: 600;
  font-size: 12px;
  color: #757576;
  height: 30px;
  flex: 1;
  border: none;
  border-radius: 20px;
  background-color: transparent;
  padding: 7px 12px;
  letter-spacing: 0.04em;
  &_active {
    background: #262a33;
    color: #ffffff;
  }
}

.sort_modal_container {
  padding: 20px;
}

.shared_with_container {
  display: flex;
  align-items: center;
}

.shared_with_text {
  color: #828c94;
  font-size: 12px;
  margin-right: 9px;
}

.user_details {
  display: flex;
  align-items: center;
}

.user_avatar {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
  }
}

.user_name {
  div {
    color: #0d1444;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    &:last-of-type {
      color: #828c94;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
    }
  }
}

.buttons_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 96px;
  background: linear-gradient(0deg, #ffffff, #ffffff), #f2f6f9;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  & :global(.button) {
    width: 128px;
    height: 36px;
    font-size: 14px;
    &:first-of-type {
      margin-right: 10px;
    }
  }
}
