@import '../../../../assets/styles/abstracts/variables';

.tipmodal {
  &__note {
    @include montserrat-14-light;
    color: #778797;
  }

  &__input-group {
    background: #262a33;
    border-radius: 2.1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 1.6rem 2rem;

    &.goal {
      margin-bottom: 1rem;

      .input-wrapper {
        position: relative;

        span {
          @include montserrat-14-normal;
          color: #fff;
          position: absolute;
          left: 2px;
          top: 2px;
        }
      }
    }
    &.reward {
      margin-bottom: 0.5rem;
    }

    label {
      @include montserrat-12-normal;
      color: #778797;
      margin-bottom: 0.5rem;
    }
    input,
    textarea {
      width: 100%;
      background: transparent;
      box-shadow: none;
      border: none;
      @include montserrat-14-normal;
      color: #fff;

      &:focus {
        outline: none;
      }
      &::placeholder {
        @include montserrat-14-normal;
        color: #778797;
      }
    }
  }
  &__consent {
    margin-bottom: 2.5rem;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }
  .char-num {
    @include montserrat-12-light;
    color: #778797;
    margin-bottom: 1.7rem;
    display: flex;
    justify-content: flex-end;

    span {
      @include montserrat-12-semi-bold;
    }
  }

  &__top {
    position: relative;
    margin-bottom: 2rem;
  }

  &__input {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 5rem;

    padding-left: 3.4rem;

    &--wrapper {
      height: 100%;
      background: #262a33;
      border: 2px solid rgba(103, 110, 125, 0.8);
      box-sizing: border-box;
      box-shadow: 0px 4px 14px rgba(162, 162, 162, 0.2);
      border-radius: 1.5rem;
    }

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      box-shadow: none;
      padding-left: 6rem;
      padding-right: 2rem;
      @include sf-16-bold-700;
      color: #fff;

      &:focus {
        outline: none;
      }

      &::placeholder {
        @include sf-16-bold-700;
        color: #cfcfcf;
      }
    }
    p {
      @include montserrat-12-normal;
      color: #757576;
      text-align: end;
      margin: 1rem 0 0;
    }
    span {
      @include montserrat-bold-700;
    }
  }

  &__addMessage {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.4rem;

    &--action {
      @include montserrat-12-semi-bold;
      color: #778797;
      cursor: pointer;
      display: inline-block;
      margin: 0 0 1.7rem 0;

      &.blue {
        color: #349bfd;
      }
    }

    textarea {
      @include montserrat-14-normal;
      color: #fff;
      padding: 1.6rem 1rem 1.7rem;
      background: #262a33;
      border-radius: 1.5rem;
      overflow-y: auto;
      border: none;
      resize: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &::placeholder {
        line-height: 1.7rem;
        color: #778797;
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    &.success {
      flex-direction: column;
    }

    .success__message {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      align-self: flex-start;
      margin-bottom: 2.5rem;

      &--icon {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background: linear-gradient(92.95deg, #53aff7 -12.15%, #2894ff 111.4%);
        box-shadow: 0px 10px 10px rgba(78, 172, 249, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1.3rem;

        svg {
          width: 1rem;
        }
      }
      p {
        @include montserrat-14-normal;
        color: #778797;
      }
    }
  }
}
