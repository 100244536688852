@import '../../assets/styles/abstracts/variables';

.wohs {
  height: 100%;
  background-color: #070707;
  padding-bottom: 4rem;

  &__wrapper {
    background: $white90deg;
    height: 100%;
    border-radius: 25px 25px 0 0;
    padding: 3rem 2rem;
    overflow-y: auto;
  }
}
