@import '../../../assets/styles/abstracts/variables';

// nav

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
}
.step {
  @include montserrat-14-normal;
  color: #fff;
  font-weight: 500;
  width: 3rem;
  height: 3rem;
  background: #c0d2e6;
  border-radius: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &.verified {
    background: linear-gradient(92.95deg, #93cfff -12.15%, #2894ff 111.4%);
  }

  &.active {
    background: linear-gradient(92.95deg, #93cfff -12.15%, #2894ff 111.4%);
    position: relative;

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 4rem;
      height: 4rem;
      border-radius: 4rem;
      border: 1px solid #79c1ff;
    }
  }
}
.line {
  height: 2px;
  width: 2rem;
  background: #e3e9f2;
  border-radius: 5px;
  margin: 0 1rem;
}

// layout

.verificationLayout {
  margin-top: 3.4rem;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.padded {
    padding: 0 2rem;
  }

  &.withBg {
    background: url(../../../assets/svg/bg-vector-1.svg), url(../../../assets/svg/bg-vector-2.svg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;

    .verificationStep {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .mb-17 {
    margin-bottom: 1.7rem;
  }
  .mb-18 {
    margin-bottom: 1.8rem;
  }
  .mb-20 {
    margin-bottom: 2rem;
  }
  .mb-24 {
    margin-bottom: 2.4rem;
  }
  .mb-13 {
    margin-bottom: 1.3rem;
  }
  .right {
    text-align: right;
  }
  .formLink {
    color: #2894ff;
  }
  .error {
    color: red;
  }

  &__header {
    @include montserrat-14-normal;
    color: #2382e1;
    font-weight: 500;
    line-height: 2.1rem;
    text-align: center;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      &:first-of-type {
        margin-right: 2rem;
      }
    }
  }

  .profileholder {
    box-shadow: unset;
  }
}

.verificationStep {
  flex: 1;
  &__title {
    @include montserrat-22-bold;
    color: #0d1444;
    text-align: center;
    letter-spacing: 0.04em;
  }

  .profileholder {
    background: transparent;
  }
}

.formDivider {
  height: 1px;
  width: 100%;
  background: #d9d9d9;
  margin: 3.4rem auto;
}

.formParagraph {
  @include montserrat-14-normal;
  margin-top: 0;
  margin-bottom: 1.7rem;
  color: #757576;
  letter-spacing: 0.02em;
}

.formSmallText {
  @include montserrat-12-normal;
  color: #778797;
  margin: 0;

  &.lightGray {
    color: #b0b0b0;
  }
}

.formikInput {
  position: relative;
  display: flex;
  align-items: center;
  height: 7rem;
  background: $white89deg;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin-bottom: 1rem;

  &__label {
    @include montserrat-12-bold;
    font-weight: 500;
    position: absolute;
    left: 2rem;
    color: #b0b0b0;
    pointer-events: none;
    transition: 0.2s all;
    transform: translateY(0);
    z-index: 1;
    &.filled {
      top: 1.6rem;
    }
  }
  &__field {
    @include montserrat-14-semi-bold;
    text-indent: 1.8rem;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    border: 2px solid transparent;
    transition:
      border 0.2s,
      padding 0.2s;

    &:disabled {
      color: #b0b0b0;
    }

    &--filled {
      padding-top: 2rem;
    }

    &:focus {
      border: 2px solid #2f98fe;
      outline: none;
    }

    &--error {
      border: 2px solid red;
      &:focus {
        border: 2px solid red;
        outline: none;
      }
    }

    &:-webkit-autofill {
      color: #b0b0b0 !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 50px white inset !important;
      box-shadow: 0 0 0 50px white inset !important;
    }
  }
  &__error {
    @include montserrat-12-normal;
    font-weight: 500;
    position: absolute;
    right: 2rem;
    color: red;
    pointer-events: none;
    transition: 0.2s all;
    transform: translateY(0);
  }
}
.withBorder {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;

  .bottomMessage {
    display: flex;
    justify-content: space-between;
  }
}

.photoSection {
  background: linear-gradient(89.85deg, #fbfbfb 0.1%, #ffffff 102.02%);
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 2rem;
  padding: 2.4rem 2rem;
  margin-bottom: 1.1rem;

  .button {
    background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
    opacity: 0.3;
    @include montserrat-14-normal;
    color: #fff;
    margin-bottom: 2.4rem;
  }

  &__title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;

    .circle {
      @include montserrat-12-semi-bold;
      display: flex;
      width: 1.6rem;
      height: 1.6rem;
      justify-content: center;
      align-items: center;
      background: #b8b8b8;
      color: #fff;
      border-radius: 2rem;
      margin-left: 1rem;
    }

    h3 {
      @include montserrat-16-semi-bold;
      color: #0d1444;
      margin: 0;
    }
  }
}

.example {
  &__wrapper {
    display: flex;
    margin-bottom: 1.4rem;
    @media (max-width: 360px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  &__image {
    margin-right: 2rem;
    @media (max-width: 360px) {
      margin-bottom: 10px;
      max-width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__text {
    &--title {
      @include montserrat-14-semi-bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1.1rem;

      p {
        color: #26b99a;
        margin: 0;
      }

      svg {
        margin-right: 0.9rem;
      }
    }
  }
}
.inputGroup {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  p {
    @include montserrat-14-normal;
    margin: 0 0 0 1rem;
  }
}

.personalInformation__form {
  margin-bottom: 5rem;
}
.personalInformation__pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  &--title {
    @include montserrat-24-semi-bold;
    line-height: 3.6rem;
    color: #0d1444;
    max-width: 23rem;
    text-align: center;
  }
}

.file-input {
  margin-bottom: 2.4rem;
  label {
    @include montserrat-14-normal;
    display: flex;
    justify-content: center;
    width: 100%;
    background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
    opacity: 0.3;
    border-radius: 160px;
    padding: 1rem;
    color: #fff;
  }
}

.photoUploadList {
  margin-bottom: 4.3rem;
}

.photoSection {
  .error {
    margin-top: 0.5rem;
  }
}

.verification-date-picker {
  z-index: 9999999;
}

.verification-country-dropdown {
  .dropdownSelect__head__text {
    width: 100%;
    input {
      width: 100%;
    }
  }
}
