@import '../../../../../../assets/styles/abstracts/variables';

.groupContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.radioLabel,
.radioLabelSelected {
  align-items: center;
  border: 1px solid #000000;
  border-radius: 160px;
  color: #000000;
  display: flex;
  height: 3.6rem;
  justify-content: center;
  margin-right: 5px;
  width: 6.2rem;
  flex-shrink: 0.6;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.radioLabelSelected {
  background: linear-gradient(157.59deg, #181920 0%, #0f0f13 100%);
  border-radius: 160px;
  color: #ffffff;
}

.text {
  @include montserrat-14-semi-bold;
  font-weight: 600;
}
