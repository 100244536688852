@import '../../../assets/styles/abstracts/variables';

.successfullChange {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  height: 80%;

  img {
    margin-top: 5rem;
    margin-bottom: 3.6rem;
    height: 11rem;
    width: 11rem;
  }

  h2 {
    @include montserrat-24-bold;
    margin: 0;
    color: #0d1444;
  }

  p {
    @include montserrat-14-normal;
    font-weight: 500;
    width: 200px;
    color: #778797;
    text-align: center;
  }
}
